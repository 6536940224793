import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ISpecialOffer } from "../models/redux-models/ILandingState";
import Pattern from "../images/pattern.png";

import sliderImg1 from "../images/poster1.avif";
import sliderImg2 from "../images/poster2.avif";
import sliderImg3 from "../images/poster3.avif";

import "./index.css";

const Container = styled.div`
  background-repeat: repeat;
  background-position: center center;
  background-attachment: fixed;
  background-image: url(${Pattern});
  background-size: contain;
  width: 100%;
  padding: 100px 0 0 0;
  background-color: #ffffff;
`;

interface ContentProps {
  offers: ISpecialOffer[];
}

const Carousel: React.FC<ContentProps> = ({ offers }) => {
  var settings = {
    dots: true,
    arrows: false,
    className: "center",
    centerMode: true,
    infinite: true,
    speed: 4000,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    autoplay: true,
    centerPadding: "100px",
    cssEase: "easeOutElastic",
  };

  return (
    <Container style={{ overflow: "hidden" }} className={`Carousel `}>
      <Slider {...settings}>
        {offers.map((offer) => (
          <div key={offer._id}>
            <a href={offer.link}>
              <img src={offer.image} alt="" />
            </a>
          </div>
        ))}
      </Slider>
    </Container>
  );
};

Carousel.propTypes = {};

Carousel.defaultProps = {};

export default Carousel;
