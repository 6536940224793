import { Endpoints } from "../constants";
import {
  IForgotPasswordRequestParams,
  ILoginRequestParams,
  IResetPasswordRequestParams,
  ISignUpRequestParams,
  ISignupResponse,
  LoginResponse,
} from "../models";
import { ForgotPasswordResponse } from "../models/ApiResponses/ForgotPasswordResponse";
import { ResetPasswordResponse } from "../models/ApiResponses/ResetPasswordResponse";
import { BaseApiClient } from "./BaseApiClient";

class AuthClient extends BaseApiClient {
  loginUser(loginParams: ILoginRequestParams) {
    const url = Endpoints.userLogin;
    return this.postJson<LoginResponse>(url, loginParams);
  }
  forgetPassword(forgetPasswordParams: IForgotPasswordRequestParams) {
    const url = Endpoints.userForgotPassword;
    return this.postJson<ForgotPasswordResponse>(url, forgetPasswordParams);
  }
  resetPassword(resetPasswordParams: IResetPasswordRequestParams) {
    const url = Endpoints.userResetPassword;
    return this.postJson<ResetPasswordResponse>(url, resetPasswordParams);
  }

  signup(signUpParams: ISignUpRequestParams) {
    const url = Endpoints.userSignup;
    return this.postJson<ISignupResponse>(url, signUpParams);
  }

  fetchUser() {
    // const url = this.constructUrl();
  }
}

export const authClient = new AuthClient();
