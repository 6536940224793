export const FloaterIcon3 = () => (
  <svg id="Applique" data-tip data-for="Applique_tooltip" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <rect id="Rectangle_121" data-name="Rectangle 121" width="36" height="36" fill="none" />
    <g id="_65668b0aef78927c7df273dc1f90d5a9" data-name="65668b0aef78927c7df273dc1f90d5a9" transform="translate(1 1)">
      <path id="Path_96" data-name="Path 96" d="M170.982,281.862h-8.844A1.941,1.941,0,0,0,160.2,283.8v2.1a1.206,1.206,0,0,0,1.2,1.2h10.3a1.2,1.2,0,0,0,1.2-1.2v-2.1A1.925,1.925,0,0,0,170.982,281.862Zm-5.4-9.78a.976.976,0,1,0,.969-.983A.978.978,0,0,0,165.585,272.083Z" transform="translate(-149.564 -253.101)" fill="rgba(66,68,71,0.62)" />
      <path id="Path_97" data-name="Path 97" d="M139.97,127.439a.058.058,0,0,0-.106.033v8.817a1.686,1.686,0,1,1-.71,0v-8.817a.058.058,0,0,0-.106-.033l-7.522,11.632a1.952,1.952,0,0,0,.465,2.6,9.258,9.258,0,0,1,3.678,5.338h7.675a9.258,9.258,0,0,1,3.678-5.338,1.938,1.938,0,0,0,.465-2.6Z" transform="translate(-122.509 -118.954)" fill="rgba(66,68,71,0.62)" />
      <path id="Path_98" data-name="Path 98" d="M31.616,14.812A14.882,14.882,0,0,0,21.458,3.167c2.642.007,5.238.013,7.536.013a2.523,2.523,0,1,0-.007-.71c-2.178-.007-5.544-.013-9.156-.02v-1A1.445,1.445,0,0,0,18.391,0H15.6a1.451,1.451,0,0,0-1.447,1.447v1c-4.641-.007-7.449-.007-9.162,0A2.483,2.483,0,1,0,5,3.16c1.819-.007,4.508-.007,7.569-.007A14.888,14.888,0,0,0,2.377,14.812a2.534,2.534,0,1,0,.71.066,14.168,14.168,0,0,1,11.074-11.4v.75a1.451,1.451,0,0,0,1.447,1.447h2.782a1.451,1.451,0,0,0,1.447-1.447V3.472a14.159,14.159,0,0,1,11.074,11.4,2.524,2.524,0,1,0,.7-.06Z" fill="rgba(66,68,71,0.62)" />
    </g>
  </svg>

);
