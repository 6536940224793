import React, { ChangeEvent, useState } from "react";
import { ISignUpRequestParams } from "../../models";
import { Validation } from "../../Utils";

import { StyledInput, StyledButton } from "./styled.component";
import {maskingPhone} from "../../Utils/common";
interface SignUpFormProps {
  handleSignUp(params: ISignUpRequestParams): void;
}

export const SignUpForm: React.FC<SignUpFormProps> = ({ handleSignUp }) => {
  const [signUpParam, setSignUpParams] = useState<ISignUpRequestParams>(
      {
          name: "",
          email: "",
          phone: "",
          password: "",
      }
  );

  const [validationError, setValidationErrors] = useState({
    nameInvalidMsg: "",
    emailInvalidMsg: "",
    phoneInvalidMsg: "",
    passwordInvalidMsg: "",
  });

  const validateForm = () => {
    const { name, email, password, phone } = signUpParam;

    const { errorMessage: nameInvalidMsg } = Validation.validateName(name);
    const { errorMessage: passwordInvalidMsg } =
      Validation.validatePassword(password);
    const { errorMessage: phoneInvalidMsg } = Validation.validatePhone(phone);
    const { errorMessage: emailInvalidMsg } = Validation.validateEmail(email);

    setValidationErrors({
      nameInvalidMsg,
      passwordInvalidMsg,
      phoneInvalidMsg,
      emailInvalidMsg,
    });

    return (
      !nameInvalidMsg &&
      !passwordInvalidMsg &&
      !phoneInvalidMsg &&
      !emailInvalidMsg
    );
  };

  const validateAndSignup = () => {
    const { name, email, password, phone } = signUpParam;

    if (validateForm()) {
      const signUpParams: ISignUpRequestParams = {
        name,
        email,
        password,
        phone,
      };

      handleSignUp(signUpParams);
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setSignUpParams({ ...signUpParam, email });

    setValidationErrors({
      ...validationError,
      emailInvalidMsg: Validation.validateEmail(email).errorMessage,
    });
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setSignUpParams({ ...signUpParam, name });

    setValidationErrors({
      ...validationError,
      nameInvalidMsg: Validation.validateName(name).errorMessage,
    });
  };

  const handleNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const num = e.target.value;
    let phone = maskingPhone(num);
    
    setSignUpParams({ ...signUpParam, phone });

    setValidationErrors({
      ...validationError,
      phoneInvalidMsg: Validation.validatePhone(phone).errorMessage,
    });
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setSignUpParams({ ...signUpParam, password });

    setValidationErrors({
      ...validationError,
      passwordInvalidMsg: Validation.validatePassword(password).errorMessage,
    });
  };

  return (
    <>
      <StyledInput
        type="text"
        placeholder="Name *"
        autoComplete="off"
        onChange={handleNameChange}
        label={validationError.nameInvalidMsg}
      />

      <StyledInput
        type="email"
        placeholder="Email *"
        autoComplete="off"
        onChange={handleEmailChange}
        label={validationError.emailInvalidMsg}
      />
      <StyledInput
        type="text"
        placeholder="Mobile Number *"
        autoComplete="off"
        onChange={handleNumberChange}
        value={signUpParam.phone}
        label={validationError.phoneInvalidMsg}
      />

      <StyledInput
        type="passoword"
        placeholder="Create Passowrd"
        autoComplete="off"
        onChange={handlePasswordChange}
        label={validationError.passwordInvalidMsg}
      />
      <StyledButton onClick={validateAndSignup}>Sign Up</StyledButton>
    </>
  );
};
