import { AnyAction } from "redux";

import {
  IAuthDetails,
  ISubcategory,
  CategoryResponse,
  LandingResponse,
  IProduct,
  HeaderResponse,
  AnnouncementResponse,
  SpecificationResponse,
  ProductResponse,
} from "../models";

import { CartResponse } from "../models/ApiResponses/ICartResponse";
import { IOneProductV2 } from "../models/ApiResponses/IGetProductResponseV2";
import { MegaMenuResponse } from "../models/ApiResponses/MegaMenuResponse";

export const SAVE_AUTH = "SAVE_AUTH";
export const CLEAR_AUTH = "CLEAR_AUTH";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_SUB_CATEGORY = "GET_SUB_CATEGORY";
export const GET_LANDING = "GET_LANDING";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_ANNOUNCEMENT = "GET_ANNOUNCEMENT";
export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const GET_HEADER = "GET_HEADER";
export const GET_CART = "GET_CART";
export const GET_SPECIFICATION = "GET_SPECIFICATION";
// lines added for mega menu.
export const GET_MEGA_MENU ="GET_MEGA_MENU";

export function saveAuthDetails(data: IAuthDetails): AnyAction {
  return {
    type: SAVE_AUTH,
    data,
  };
}

export function clearAuthDetails(): AnyAction {
  return {
    type: CLEAR_AUTH,
  };
}

export function getCategory(data: CategoryResponse): AnyAction {
  return {
    type: GET_CATEGORY,
    data,
  };
}

export function getSubCategory(data: Array<ISubcategory>): AnyAction {
  return {
    type: GET_SUB_CATEGORY,
    data,
  };
}

export function getLanding(data: LandingResponse): AnyAction {
  return {
    type: GET_LANDING,
    data,
  };
}

export function getProducts(data: ProductResponse): AnyAction {
  return {
    type: GET_PRODUCTS,
    data,
  };
}

export function getAnnouncement(data: AnnouncementResponse): AnyAction {
  return {
    type: GET_ANNOUNCEMENT,
    data,
  };
}
export function getProductList(data: Array<IProduct>) {
  return {
    type: GET_PRODUCT_LIST,
    data,
  };
}

export function getHeader(data: HeaderResponse): AnyAction {
  return {
    type: GET_HEADER,
    data,
  };
}

export function getCart(data: CartResponse): AnyAction {
  return {
    type: GET_CART,
    data,
  };
}

export function getSpecification(data: SpecificationResponse): AnyAction {
  return {
    type: GET_SPECIFICATION,
    data,
  };
}

//lines added for mega menu.
export function getMegaMenu(data: MegaMenuResponse): AnyAction {
  return {
    type: GET_MEGA_MENU,
    data,
  };
}
