import React, { ChangeEvent } from "react";
import { Modal } from "@happeouikit/modals";
import { Checkbox, Dropdown, Input, RadioButton } from "@happeouikit/form-elements";
import { BodyUI } from "@happeouikit/typography";
import styled from "styled-components";
import {
  Embroidary,
  Embroidary_Locations,
  marginM,
  marginS,
} from "../constants";
import {
  EmboidaryOptionValues,
  GREEK_ALPHABETS,
  IFontStyle,
  PreviewEmbroidarySettingNames,
  PreviewSettingNames,
} from "../models";
import {
  EmboidaryInputType,
  EmbroidaryID,
  IColorV2,
} from "../models/ApiResponses/IGetProductResponseV2";
import { FontStyles } from "../widgets/shirtDesigner/fontStyle";
import {pascalCase, displayEmbdName, flippedText, isNumberLetters, isGreekLetters} from "../Utils";


interface AdditionalDesignModalProps {
  isOpen: boolean;
  close(): void;
  emboidaryOptionValues?: EmboidaryOptionValues;
  updateEmbroidaryLocations(emboidaryLoc: any, callback: any): void;
  embroidaryOption?: EmbroidaryID;
  onDone(
    enteredValues: Record<string, Record<string, string>>,
    embroidaryOption: any
  ): void;
  selectedSide: string;
  embroidaryLocations: {
    [key: string]: {};
  };
  appliquePreviewData: any;
  selectedColor?: IColorV2;
}

interface AdditionalDesignModalState {
  isModalDisable: boolean;
  embroidaryOption: any;
}

export class AdditionalDesignModal extends React.Component<
  AdditionalDesignModalProps,
  AdditionalDesignModalState
> {
  private selectedData: any = {};
  private embroidaryOptionItems: any = [];

  constructor(props: AdditionalDesignModalProps) {
    super(props);

    this.state = {
      isModalDisable: false,
      embroidaryOption: this.props.embroidaryOption
    };

    this.getOptionValuesForCurrentKey =
      this.getOptionValuesForCurrentKey.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<AdditionalDesignModalProps>, prevState: Readonly<AdditionalDesignModalState>, snapshot?: any): void {
    console.log("componentDidUpdate--------");
    console.log("prevProps : ", prevProps);
    console.log("prevState : ", prevState);
    console.log("snapshot : ", snapshot);
    if(prevProps.embroidaryOption != this.props.embroidaryOption){
      console.log("embroidaryOption changed");
      const {
        embroidaryOption
      } = this.props;

      this.setState({
        embroidaryOption: embroidaryOption
      });
      
      this.embroidaryOptionItems = embroidaryOption && embroidaryOption.keys?Object.keys(embroidaryOption.keys):[];
      console.log("embroidaryOptionItems : ", this.embroidaryOptionItems);


      /* if single item the mark as checked */
      if(this.embroidaryOptionItems.length == 1){
        this.updateEmbroidaryLocations(embroidaryOption, this.embroidaryOptionItems[0], {
          checked: true, 
        });
      }
    }

    if(typeof this.state.embroidaryOption !== undefined){
      this.checkDisabilityForButton();
    }
  }

  getValue(name: string, d: any) {
    if (name === "threadToMatchs") {
      return d.value;
    } else if (name === Embroidary.design) {
      return d.image;
    } else {
      return d._id;
    }
  }

  getOptionValuesForCurrentKey(name: string) {
    const { emboidaryOptionValues, selectedSide } = this.props;
    if ((emboidaryOptionValues as any)[name]) {
      const options = (emboidaryOptionValues as any)[name].map((d: any) => {
        return {
          label: d.name,
          price: typeof d.price !== "undefined"?d.price:0,
          value: this.getValue(name, d),
        };
      });
      if (options && Array.isArray(options)) {
        //console.log("embd NAME : ", name)
        //console.log("options ==:== ", options);
        if (name === Embroidary.embroidaryLocation) {
          console.log("this.props.appliquePreviewData := ", this.props.appliquePreviewData);
          const selectedAppliqueContextText =
            this.props.appliquePreviewData.find((i: any) => {
              return i.settingName === PreviewSettingNames.contentText;
            }).settingVal.value.trim();
            console.log("selectedAppliqueContextText := ", selectedAppliqueContextText);
          if (selectedAppliqueContextText !== "") {
            if(isGreekLetters(selectedAppliqueContextText)){
              return options.filter((i) => {
                return (
                  i.label.includes(this.props.selectedSide) ||
                  i.label === Embroidary.aboveGreekLetter ||
                  i.label === Embroidary.overGreekLetter ||
                  i.label === Embroidary.underGreekLetter
                );
              }).sort(function(a:any, b:any){
                if(a.label < b.label) { return -1; }
                if(a.label > b.label) { return 1; }
                return 0;
              });
            } else if(isNumberLetters(selectedAppliqueContextText)) {
              return options.filter((i) => {
                return (
                  i.label.includes(this.props.selectedSide) ||
                  i.label === Embroidary.underNumber
                );
              });
            } else {
              
                return options.filter((i) => {
                  return i.label
                    .toLowerCase()
                    .includes(
                      this.props.selectedSide.indexOf("_") !== -1
                        ? this.props.selectedSide.split("_")[1]
                        : this.props.selectedSide
                    );
                });
              
  
              /*if(selectedOptions.length == 0){
                return options.filter((i) => {
                  return i;
                }).sort(function(a:any, b:any){
                  if(a.label < b.label) { return -1; }
                  if(a.label > b.label) { return 1; }
                  return 0;
                });
              } else {
                return selectedOptions;
              }*/
            }
          } else { 
            console.log("selectedSide ::= ", selectedSide);
            if(selectedSide == "arm_left"){
              console.log("options ::= ", options.filter((i) => {
                return i.label
                  .toLowerCase()
                  .includes(
                    /*this.props.selectedSide.indexOf("_") !== -1
                      ? this.props.selectedSide.split("_")[1]
                      : this.props.selectedSide*/
                      'above text'
                  ) || i.label
                  .toLowerCase()
                  .includes('under text')
                  || i.label
                  .toLowerCase()
                  .includes('over text');
              }));
              return options.filter((i) => {
                return i.label
                  .toLowerCase()
                  .includes(
                    /*this.props.selectedSide.indexOf("_") !== -1
                      ? this.props.selectedSide.split("_")[1]
                      : this.props.selectedSide*/
                      'above text'
                  ) || i.label
                  .toLowerCase()
                  .includes('under text')
                  || i.label
                  .toLowerCase()
                  .includes('over text');
              });
            } else if(selectedSide == "arm_right"){
              console.log("options ::= ", options.filter((i) => {
                return i.label
                  .toLowerCase()
                  .includes(
                    /*this.props.selectedSide.indexOf("_") !== -1
                      ? this.props.selectedSide.split("_")[1]
                      : this.props.selectedSide*/
                      'above text'
                  ) || i.label
                  .toLowerCase()
                  .includes('under text')
                  || i.label
                  .toLowerCase()
                  .includes('over text');
              }));
              return options.filter((i) => {
                return i.label
                  .toLowerCase()
                  .includes(
                    /*this.props.selectedSide.indexOf("_") !== -1
                      ? this.props.selectedSide.split("_")[1]
                      : this.props.selectedSide*/
                      'above text'
                  ) || i.label
                  .toLowerCase()
                  .includes('under text')
                  || i.label
                  .toLowerCase()
                  .includes('over text');
              });
            } else {
              return options.filter((i) => {
                return i.label
                  .toLowerCase()
                  .includes(
                    this.props.selectedSide.indexOf("_") !== -1
                      ? this.props.selectedSide.split("_")[1]
                      : this.props.selectedSide
                  );
              });

              /*if(selectedOptions.length == 0){
                return options.filter((i) => {
                  return i;
                }).sort(function(a:any, b:any){
                  if(a.label < b.label) { return -1; }
                  if(a.label > b.label) { return 1; }
                  return 0;
                });
              } else {
                return selectedOptions;
              }*/
            }
          }
        } else if(name === Embroidary.fontStyles){
          return options.filter((i) => {
            return i.label
              .toLowerCase() != 'college slab';
          });
        }
        return options;
      }
    }
    return [];
  }

  renderHeaderText(name: string) {
    return (
      <StyledHeader>
        <div>
          <h2>{name === "Crest and Images(price varies with size and design)"? "Crest and Images ( Price Varies With Size And Design)":name} </h2>
          {/*<p>
            Current View: <b>{pascalCase(displayEmbdName(this.props.selectedSide.toUpperCase()))} </b>
          </p>*/}
        </div>
        <StyledAnchor>
          <a href="/fonts" target="_blank">
            View Fonts
          </a>
          <a href="/colors" target="_blank">
            View Colors
          </a>
          <a href="/size-and-location" target="_blank">
            View Sizes
          </a>
        </StyledAnchor>
      </StyledHeader>
    );
  }

  getCurrentState(key: string) {
    const { embroidaryLocations, selectedSide } = this.props;
    const {embroidaryOption} = this.state;
    if (embroidaryOption) {
      if (
        Object.keys(embroidaryLocations[selectedSide]).includes(
          embroidaryOption._id
        )
      ) { 
        const selected = (embroidaryLocations[selectedSide] as any)[
          embroidaryOption?._id
        ];
        return selected[key].checked ? true : false;
      }
      return false;
    }
  }

  updateEmbroidaryLocations(
    embroidaryOption: any,
    key: string,
    option: any,
    currentField?: any,
    callBack = () => {}
  ) {
    
    const { selectedSide, embroidaryLocations, emboidaryOptionValues } =
      this.props;
    let temp = {};
    if (
      currentField &&
      (currentField.name === "fontSize" || currentField.name === "fontStyles")
    ) {
      const { textLine1, textLine2, textLine3, ...a } = (
        embroidaryLocations[selectedSide] as any
      )[embroidaryOption._id][key];
      let b = {};
      if (currentField.name === "fontSize") {
        let { label: fontSizeLabel, value: fontSizeValue } = option["fontSize"];
        b = {
          fontSizeLabel: fontSizeLabel.split('"')[0],
          fontSizeValue: fontSizeValue,
          fontStyles: a["fontStyles"].label,
          fontStylesValue: a["fontStyles"].value,
        };
      } else {
        let { label: fontStylesLabel, value: fontStylesValue } =
          option["fontStyles"];
        b = {
          fontSizeLabel: fontStylesLabel,
          fontSizeValue: a["fontSize"].value,
          fontStyles: a["fontSize"].label.split('"')[0],
          fontStylesValue: fontStylesValue,
        };
      }
      console.log("b : ",b);
      if ((b as any)["fontStylesValue"] && (b as any)["fontSizeValue"]) {
        const selectedFontStyle = (
          emboidaryOptionValues as any
        ).fontStyles.find((i: any) => {
          return i._id == (b as any)["fontStylesValue"];
        });
        console.log("selectedFontStyle : ", selectedFontStyle);
        const selectedCharLength = selectedFontStyle.charLength.find((i: any) => {
          return i.fsId === (b as any)["fontSizeValue"];
        });
        const maxLength = typeof selectedCharLength !== "undefined"?selectedCharLength.maxLength:9; //default text length for all testSizes is 9.

        (temp as any)["textLine1"] = textLine1.slice(0, maxLength);
        if (textLine2) {
          (temp as any)["textLine2"] = textLine2.slice(0, maxLength);
        }
        if (textLine3) {
          (temp as any)["textLine3"] = textLine3.slice(0, maxLength);
        }
      }
    }
    /*console.log("updateEmbroidaryLocations - embroidaryLocations", {
      ...embroidaryLocations,
      [selectedSide]: {
        ...embroidaryLocations[selectedSide],
        [embroidaryOption._id]: {
          ...(embroidaryLocations[selectedSide] as any)[embroidaryOption._id],
          [key]: {
            ...(embroidaryLocations[selectedSide] as any)[
              embroidaryOption._id
            ][key],
            ...option,
            ...temp,
          },
        },
      },
    });*/
    console.log("embroidaryOption : ",embroidaryOption, "key : ",key,"option : ",option,"currentField : ",currentField,"callBack : ",callBack);
    this.props.updateEmbroidaryLocations(
      {
        ...embroidaryLocations,
        [selectedSide]: {
          ...embroidaryLocations[selectedSide],
          [embroidaryOption._id]: {
            ...(embroidaryLocations[selectedSide] as any)[embroidaryOption._id],
            [key]: {
              ...(embroidaryLocations[selectedSide] as any)[
                embroidaryOption._id
              ][key],
              ...option,
              ...temp,
            },
          },
        },
      },
      () => {
        const { embroidaryLocations, selectedSide } = this.props;
        for (let i in embroidaryLocations[selectedSide]) {
          for (const [key, value] of Object.entries(
            (embroidaryLocations[selectedSide] as any)[i]
          )) {
            if ((value as any).checked) {
              this.checkValues(value, key);
            } else {
              delete this.selectedData[key];
              this.checkDisabilityForButton();
            }
          }
        }

        callBack();
      }
    );
  }

  checkValues(data: any, selectedKey: string) {
    this.selectedData[selectedKey] = true;
    for (const [key, value] of Object.entries(data)) {
      const label = (value as any).label;
      if (label && label.includes("Select") && key != "checked") {
        this.selectedData[selectedKey] = false;
        break;
      } else if (
        (key == "textLine1" && value == "") ||
        (key == "text" && value == "")
      ) {
        this.selectedData[selectedKey] = false;
        break;
      }
    }
    this.checkDisabilityForButton();
  }

  checkNumber(str: string) {
    return !/\d/.test(str);
  }

  getMaxLength(embroidaryOption: any, key: string) {
    const { embroidaryLocations, selectedSide, emboidaryOptionValues } =
      this.props;
    const { fontStyles, fontSize } = (embroidaryLocations[selectedSide] as any)[
      embroidaryOption._id
    ]
      ? (embroidaryLocations[selectedSide] as any)[embroidaryOption._id][key]
      : { fontStyles: "false", fontSize: "false" };
    if (fontSize && fontStyles) {
      const { value: fontSizeValue } = fontSize;
      const { value: fontStylesValue } = fontStyles;
      if (fontSizeValue && fontStylesValue) {
        const selectedFontStyle = (emboidaryOptionValues as any)[
          "fontStyles"
        ].find((i: any) => {
          return i._id === fontStylesValue;
        });
        const selectedFontSize = selectedFontStyle?.charLength.find(
          (i: any) => {
            return i.fsId === fontSizeValue;
          }
        );
        if (selectedFontSize && selectedFontStyle) {
          return selectedFontSize["maxLength"];
        }
      }
      return undefined;
    }

    return undefined;
  }

  checkDisabilityForButton() {
    //console.log("this.selectedData : ", this.selectedData);
    let status: boolean = false;
    if (Object.keys(this.selectedData).length > 0) {
      for (const i in this.selectedData) {
        if (this.selectedData[i] == false && this.embroidaryOptionItems.indexOf(i) !== -1) {
          status = true;
        }
      }
    } else {
      status = false;
    }
    //console.log("status : ", status);
    //console.log("this.state.isModalDisable : ", this.state.isModalDisable);
    if(this.state.isModalDisable != status){
      //console.log("changed");
      this.setState({
        isModalDisable: status,
      });
    }
  }

  addMoreTextOptions(index:number){
    index = index + 1;
    const {
      selectedSide
    } = this.props;
    const {embroidaryOption} = this.state;

    let embKey = "";
    switch(selectedSide){
      case "arm_left": embKey="Left"; break;
      case "arm_right": embKey="Right"; break;
    }  

    if(embKey != "" && embroidaryOption){
      let embroidaryOptionList = (embroidaryOption as any).keys[embKey];
      /* embroidaryOptionList.push({
          "label": "Text line4",
          "name": "textLine4",
          "inputType": "text"
      }); */
      embroidaryOptionList.push({
          "label": "Text line"+index,
          "name": "textLine"+index,
          "inputType": "text"
      });

      this.setState({
        embroidaryOption: {
          ...embroidaryOption,
          keys: {
            ...embroidaryOption.keys,
            [embKey]: embroidaryOptionList
          }
        }
      });
    }
  }

  removeTextOption(index:number){
    const {
      selectedSide,
      embroidaryLocations
    } = this.props;
    const {embroidaryOption} = this.state;

    let embKey = "";
    switch(selectedSide){
      case "arm_left": embKey="Left"; break;
      case "arm_right": embKey="Right"; break;
    }

    console.log("removeTextOption index : ", index);

    if(embKey != "" && embroidaryOption){
      let embroidaryOptionList = (embroidaryOption as any).keys[embKey];
      console.log("embroidaryOptionList : ", embroidaryOptionList);
      /* find index */
      let findDeleteIndex = embroidaryOptionList.findIndex((itm:any) => itm.name == "textLine"+index);
      console.log("findDeleteIndex : ", findDeleteIndex);
      let deletedItem = embroidaryOptionList.splice(findDeleteIndex, 1).pop();
      console.log("deletedItem: ", deletedItem);
      this.setState({
        embroidaryOption: {
          ...embroidaryOption,
          keys: {
            ...embroidaryOption.keys,
            [embKey]: embroidaryOptionList
          }
        }
      }, () => {
        console.log("embroidaryOption afer update : ",embroidaryOption);
        let embroidaryLocationsData = embroidaryLocations;
        delete (embroidaryLocationsData[selectedSide] as any)[
          embroidaryOption._id
        ][embKey][deletedItem.name];
        delete (embroidaryLocationsData[selectedSide] as any)[
          embroidaryOption._id
        ][embKey][deletedItem.name+"_actual"];
        /* update deleted option value to blank */
        this.updateEmbroidaryLocations(
          embroidaryOption,
          embKey,
          (embroidaryLocationsData[selectedSide] as any)[
            embroidaryOption._id
          ][embKey],
          deletedItem,
          () => {
            
          }
        )
      });
    }
  }

  render(): React.ReactNode {
    const {
      isOpen,
      close,
      //embroidaryOption,
      selectedSide,
      onDone,
      embroidaryLocations,
      selectedColor,
    } = this.props;
    const {embroidaryOption} = this.state;

    console.log("selectedSide : ", selectedSide);
    console.log("embroidaryLocations : ", embroidaryLocations);
    console.log("embroidaryOption : ", embroidaryOption);
    console.log(
      "embroidaryLocations[selectedSide] : ",
      embroidaryLocations[selectedSide]
    );
    

    //const embroidaryOptionItems = embroidaryOption && embroidaryOption.keys?Object.keys(embroidaryOption.keys):[];
    console.log("embroidaryOptionItems : ", this.embroidaryOptionItems);


    return (
      <Modal
        isOpen={isOpen}
        close={close}
        headerText={this.renderHeaderText(embroidaryOption?.name || "")}
        width={"984px"}
        disabled={this.state.isModalDisable}
        okCallback={() => {
          onDone(
            this.props.embroidaryLocations[selectedSide],
            this.props.embroidaryOption
          );
          close();
        }}
        cancelText={"Cancel"}
        overlayStyle={{ zIndex: "1061" }}
      >
        {embroidaryOption && embroidaryOption.keys && (
          <div style={{ display: "flex", flexFlow: "row wrap" }}>
            {Object.keys(embroidaryOption.keys).map((key:string, idx) => {
              /* if(selectedSide == "collar" && key == "On_Collar_Flipper_Up"){
                return false;
              } else if(selectedSide == "collar_flipper_up" && key == "On_Collar"){
                return false;
              } */ 

              const value = embroidaryOption.keys[key];
              let textOptionCount = 0;
              console.log("key : ", key);
              console.log("value : ", value);
              let image = "";

              if (
                (embroidaryLocations[selectedSide] as any)[
                  embroidaryOption._id
                ] &&
                (embroidaryLocations[selectedSide] as any)[
                  embroidaryOption._id
                ][key] &&
                (embroidaryLocations[selectedSide] as any)[
                  embroidaryOption._id
                ][key][Embroidary.design] &&
                (embroidaryLocations[selectedSide] as any)[
                  embroidaryOption._id
                ][key][Embroidary.design].value !== ""
              ) {
                const selectedEmDesign = (
                  this.props.emboidaryOptionValues as any
                )[Embroidary.design].find((i: any) => {
                  return (
                    i.name ===
                    (embroidaryLocations[selectedSide] as any)[
                      embroidaryOption._id
                    ][key][Embroidary.design].label
                  );
                });
                if (selectedEmDesign) {
                  image = selectedEmDesign.image;
                }
              } else {
                image =
                  (embroidaryOption.images as any)[idx] &&
                  (embroidaryOption.images as any)[idx].includes("http")
                    ? (embroidaryOption.images as any)[idx]
                    : (selectedColor as any).images[
                        (embroidaryOption.images as any)[key]
                      ];
              }

              switch(key){
                case "Top": 
                  image = "http://favorsbythebay.com/apis/top_of_back.gif";
                break;
                case "UnderNumber": 
                  image = "http://favorsbythebay.com/apis/under_number.gif";
                break;
                case "Bottom": 
                  image = "http://favorsbythebay.com/apis/bottom_of_back.gif";
                break;
                case "Left":
                  if(selectedSide == "cuff_left"){
                    image = "http://favorsbythebay.com/apis/cuff.jpg";
                  } else {
                    image = "http://favorsbythebay.com/apis/left_arm.jpg";
                  }
                break;
                case "Right":
                  if(selectedSide == "cuff_right"){
                    image = "http://favorsbythebay.com/apis/cuff.jpg";
                  } else {
                    image = "http://favorsbythebay.com/apis/right_arm.jpg"
                  }
                break;
                case "On_Collar":
                  image = "http://favorsbythebay.com/apis/on_back_collar.jpg";
                break;
                case "On_Collar_Flipped_Up":
                  image = "http://favorsbythebay.com/apis/CollerFlippedUp.jpeg";
                break;
              }

              console.log("image : ", image);
              const selectedAppliqueContextText =
                this.props.appliquePreviewData.find((i: any) => {
                  return i.settingName === PreviewSettingNames.contentText;
                });

              // if (
              //   key !== PreviewEmbroidarySettingNames.Design_1 &&
              //   key !== PreviewEmbroidarySettingNames.Design_2
              // ) {
              //   if (selectedSide.includes("arm")) {
              //     if (!selectedSide.includes(key.toLowerCase())) {
              //       return;
              //     }
              //   }
              // }

              if (
                selectedAppliqueContextText ||
                this.props.appliquePreviewData.length === 0
              ) {
                if (
                  key === Embroidary_Locations.Over_Kappa &&
                  selectedAppliqueContextText.settingVal.value
                    .trim()
                    .indexOf(GREEK_ALPHABETS.Kappa) === -1
                ) {
                  return;
                }
                if (
                  key === Embroidary_Locations.UnderNumber &&
                  this.checkNumber(
                    selectedAppliqueContextText.settingVal.value.trim()
                  )
                ) {
                  return;
                }
              }
              console.log("key : ", key);
              console.log("selectedSide : ", selectedSide);
              console.log("embroidaryOption : ", embroidaryOption);
              console.log("embroidaryLocations[selectedSide] : ", embroidaryLocations[selectedSide]);
              let optionName = key.replace(/_/g," ");
              switch(true){
                case optionName == Embroidary_Locations.UnderNumber:  optionName = "Under Number"; break;
              }

              

              return (
                <ContentWrapper key={key} style={{ flex: "50%" }}>
                  {this.embroidaryOptionItems.length > 1 && (selectedSide !== "hood" ?<Checkbox
                    name={"embdOptCheck"}
                    label={optionName}
                    checked={this.getCurrentState(key)}
                    onChange={() =>
                      this.updateEmbroidaryLocations(embroidaryOption, key, {
                        checked: !(embroidaryLocations[selectedSide] as any)[
                          embroidaryOption._id
                        ][key].checked,
                      })
                    }
                    
                  />:<RadioButton
                    name={"embdOptChoose"}
                    label={optionName}
                    checked={this.getCurrentState(key)}
                    /* onChange={() =>
                      this.updateEmbroidaryLocations(embroidaryOption, key, {
                        checked: !(embroidaryLocations[selectedSide] as any)[
                          embroidaryOption._id
                        ][key].checked,
                      })
                    } */
                    onClick={(e:any) => {
                      console.log(e.target.checked);
                      this.updateEmbroidaryLocations(embroidaryOption, key, {
                        checked: true/* !(embroidaryLocations[selectedSide] as any)[
                          embroidaryOption._id
                        ][key].checked */,
                      }, false, () => {
                        const otherEmbroidaryOptionItems = this.embroidaryOptionItems.filter((itm:any) => itm !== key);
                        otherEmbroidaryOptionItems.map((itm:string) => {
                          this.updateEmbroidaryLocations(embroidaryOption, itm, {
                            checked: false,
                          });
                        });
                      });
                    }}
                  />)}
                  <ContentContainer>
                    <Content>
                      <DisplayImage src={image} alt="" />
                    </Content>
                    <Content marginL>
                      {value.map((currentField:any, fieldIndex: string) => {
                        if (
                          currentField.inputType === EmboidaryInputType.Select
                        ) {
                          return (
                            <StyledDropdown
                              key={`currentField_${fieldIndex}`}
                              placeholder={currentField.label}
                              options={[
                                {
                                  label: `Select ${currentField.label}`,
                                  value: "",
                                },
                                ...this.getOptionValuesForCurrentKey(
                                  currentField.name
                                ),
                              ]}
                              onChange={(selectedOption: any) =>
                                this.updateEmbroidaryLocations(
                                  embroidaryOption,
                                  key,
                                  {
                                    [currentField.name]: selectedOption,
                                  },
                                  currentField
                                )
                              }
                              value={
                                (embroidaryLocations[selectedSide] as any)[
                                  embroidaryOption._id
                                ] &&
                                (embroidaryLocations[selectedSide] as any)[
                                  embroidaryOption._id
                                ][key][currentField.name]
                              }
                              disabled={!this.getCurrentState(key)}
                            />
                          );
                        } else if (
                          currentField.inputType === EmboidaryInputType.Text
                        ) {
                          textOptionCount++;
                          return (
                            <InputContainer key={`currentField_${fieldIndex}`}>
                              <Input
                                disabled={!this.getCurrentState(key)}
                                type="text"
                                placeholder={currentField.label}
                                maxLength={this.getMaxLength(
                                  embroidaryOption,
                                  key
                                )}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                  this.updateEmbroidaryLocations(
                                    embroidaryOption,
                                    key,
                                    {
                                      [currentField.name]: (selectedSide == "hood" && key == "Hood_Forward")?e.target.value.slice(
                                        0,
                                        this.getMaxLength(embroidaryOption, key)
                                      ):e.target.value.slice(
                                        0,
                                        this.getMaxLength(embroidaryOption, key)
                                      ),
                                      [currentField.name+"_actual"]: e.target.value.slice(
                                        0,
                                        this.getMaxLength(embroidaryOption, key)
                                      )
                                    },
                                    currentField,
                                    () => {
                                      
                                    }
                                  )
                                }
                                value={
                                  (embroidaryLocations[selectedSide] as any)[
                                    embroidaryOption._id
                                  ] &&
                                  (embroidaryLocations[selectedSide] as any)[
                                    embroidaryOption._id
                                  ][key][currentField.name]?((selectedSide == "hood" && key == "Hood_Forward")?(embroidaryLocations[selectedSide] as any)[
                                    embroidaryOption._id
                                  ][key][currentField.name+"_actual"]:(embroidaryLocations[selectedSide] as any)[
                                    embroidaryOption._id
                                  ][key][currentField.name]):""
                                }
                              />
                              {(selectedSide === "arm_left" || selectedSide === "arm_right") && <a href="javascript:void(0);" onClick={() => this.removeTextOption(textOptionCount)}>Remove</a>}
                            </InputContainer>
                          );
                        } else {
                          return <></>;
                        }
                      })}
                      {(selectedSide === "arm_left" || selectedSide === "arm_right") && key != "Design_#1" && key != "Design_#2" && <a href="javascript:void(0);" onClick={() => this.addMoreTextOptions(textOptionCount)}>Add more</a>}
                    </Content>
                  </ContentContainer>
                </ContentWrapper>
              );
            })}
          </div>
        )}
      </Modal>
    );
  }
}

const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  width: 50%;
`;

const ContentContainer = styled.div`
  display: flex;
`;

const Content = styled.div<{ marginL?: boolean }>`
  margin-left: ${(p) => (p.marginL ? marginM : 0)};
  .dropdown__control {
    margin-bottom: ${marginS};
  }
`;

const DisplayImage = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const InputContainer = styled.div`
  margin-top: ${marginS};
`;

const StyledDropdown = styled(Dropdown)<{ disabled: boolean }>`
  pointer-events: ${({ disabled }) => (disabled ? "none" : "inherit")};

  & .dropdown__control {
    border: ${({ disabled }) =>
      disabled
        ? "1px solid rgb(232, 236, 242)"
        : "1px solid rgb(186, 198, 208)"};
    background: ${({ disabled }) =>
      disabled ? "rgb(239, 239, 239)" : "rgb(255, 255, 255)"};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const StyledAnchor = styled.div`
  a {
    margin-right: 20px;
    color: rgb(20, 47, 142);
    font-weight: bold;
  }
`;
