export enum PreviewSettingNames {
  contentText = "contentText",
  fontFamily = "font-family",
  letterPlacement = "letter-placement",
  letterSize = "font-size",
  stitchType = "stitch-type",
  specialInstructions = "special-instructions",
  clothColor = "fill",
  foreGroundFillStrokeMarker = "foreground-fill-stroke-marker",
  backGroundFillStrokeMarker = "background-fill-stroke-marker",
  design = "design",
  imageSize = "imageSize",
  embroidaryLocation = "embroidaryLocation",
  customFilePreview = "customFilePreview"
}

export enum PreviewEmbroidarySettingNames {
  Above = "Above",
  AboveText = "AboveText",
  Over_Kappa = "Over_Kappa",
  Under = "Under",
  UnderText = "UnderText",
  UnderNumber = "UnderNumber",
  Top = "Top",
  Over = "Over",
  OverText = "OverText",
  Bottom = "Bottom",
  Right = "Right",
  Left = "Left",
  Design_1 = "Design_#1",
  Design_2 = "Design_#2",
  Hood_Forward = "Hood_Forward",
  Hood_Backward = "Hood_Backward",
  On_Collar = "On_Collar",
  On_Collar_Flipped_Up = "On_Collar_Flipped_Up"
}
