import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { TextBeta, TextTheta } from "@happeouikit/typography";
import styled from "styled-components";
import {
  marginM,
  marginS,
  marginXL,
  paddingM,
  paddingS,
  primaryColor,
  marginL,
} from "../../constants";
import { StyeledCheckbox } from "../../pages/Fundraise/styled.component";
import { AdditionalDesignModal } from "../../components/AdditionalDesignModal";
import {
  EmbroidaryID,
  IColorV2,
  IOneProductV2,
} from "../../models/ApiResponses/IGetProductResponseV2";
import {
  DesignerViewStates,
  IAppState,
  PreviewSettingNames,
  UpdatePreviewParams,
} from "../../models";
import { categoryClient } from "../../ApiClients";
import { Dispatch } from "redux";
import {
  updateEmbroidaryRedux,
  updateEmbroidaryToCart,
  addPriceToCart,
  updatePriceToCart,
  removePriceToCart,
  updateEmbdryFrontPriceToCart,
  updateEmbdryBackPriceToCart,
  updateEmbdryLeftPriceToCart,
  updateEmbdryRightPriceToCart,
  updateEmbdryLeftCuffPriceToCart,
  updateEmbdryRightCuffPriceToCart,
  updateEmbdryCollarPriceToCart,
  updateEmbdryCollarFlippedUpPriceToCart,
  updateEmbdryHoodPriceToCart,
  updateEmbdryHoodForwardPriceToCart,
  updateEmbdryHoodBackwardPriceToCart
} from "../../actions";

import {pascalCase, displayEmbdName, flippedText} from "../../Utils/common";

interface EmboideryLocationsProps {
  updatePreview(params: UpdatePreviewParams, key: string): void;
  onNext(
    enteredValues: Record<string, { emboidaryOption: EmbroidaryID; value: any }>
  ): void;
  onBack(): void;
  embroidaryOptions: Array<EmbroidaryID>;
  selectedSide: string;
  appliquePreviewData: any;
  selectedProduct: IOneProductV2;
  designViews: any;
  updateEmbroidaryDesignViews(data: any): void;
  selectedColor?: IColorV2;
  editCartProduct: any;
}

interface EmboideryLocationsRedux {
  appliqueView: any;
  embroidaryView: any;
  totalQuantity: any;
  cartData: any;
  updateEmbroidaryRedux(embroidaryView: any): void;
  updateEmbroidaryToCart(data: any): void;
  addPriceToCart(data: any): void;
  updatePriceToCart(data: any): void;
  removePriceToCart(data: any): void;
  updateEmbdryFrontPriceToCart(data: any): void;
  updateEmbdryBackPriceToCart(data: any): void;
  updateEmbdryLeftPriceToCart(data: any): void;
  updateEmbdryRightPriceToCart(data: any): void;
  updateEmbdryLeftCuffPriceToCart(data: any): void;
  updateEmbdryRightCuffPriceToCart(data: any): void;
  updateEmbdryCollarPriceToCart(data: any): void;
  updateEmbdryCollarFlippedUpPriceToCart(data: any): void;
  updateEmbdryHoodPriceToCart(data: any): void;
  updateEmbdryHoodForwardPriceToCart(data: any): void;
  updateEmbdryHoodBackwardPriceToCart(data: any): void;
}

interface EmboideryLocationsState {
  // emboidaryOptionValues?: EmboidaryOptionValues;
  emboidaryOptionValues?: any;

  selectedEmbroidaryOption?: EmbroidaryID;
  embroidaryLocations: {
    [key: string]: {};
  };
}

class EmboideryLocations extends Component<
  EmboideryLocationsProps & EmboideryLocationsRedux,
  EmboideryLocationsState
> {
  private enteredValues: Record<
    string,
    { emboidaryOption: EmbroidaryID; value: any }
  > = {};
  private cartEmbroidaryData: any = [];

  constructor(props: EmboideryLocationsProps & EmboideryLocationsRedux) {
    super(props);
    //console.log("props.embroidaryOptions ::: ", props.embroidaryOptions);
    const array = this.createInitialState(props.embroidaryOptions);
    /* console.log("array : ", array); */
    const { embroidaryView, selectedSide, editCartProduct } = props;
    /* console.log("embroidaryView ", embroidaryView);
    console.log("designViews ", this.props.designViews); */

    const frontObj = this.props.designViews["embroidary"].find((i: any) => {
      return i.view === "front";
    });
    const backObj = this.props.designViews["embroidary"].find((i: any) => {
      return i.view === "back";
    });
    const arm_rightObj = this.props.designViews["embroidary"].find((i: any) => {
      return i.view === "arm_right";
    });
    const arm_leftObj = this.props.designViews["embroidary"].find((i: any) => {
      return i.view === "arm_left";
    });
    const arm_rightCuffObj = this.props.designViews["embroidary"].find((i: any) => {
      return i.view === "cuff_right";
    });
    const arm_leftCuffObj = this.props.designViews["embroidary"].find((i: any) => {
      return i.view === "cuff_left";
    });
    const collarObj = this.props.designViews["embroidary"].find((i: any) => {
      return i.view === "on_collar";
    });

    const collarFlippedUpObj = this.props.designViews["embroidary"].find((i: any) => {
      return i.view === "collar_flipped_up";
    });

    const hoodObj = this.props.designViews["embroidary"].find((i: any) => {
      return i.view === "hood";
    });

    const hoodForwardObj = this.props.designViews["embroidary"].find((i: any) => {
      return i.view === "hood_forward";
    });

    const hoodBackwardObj = this.props.designViews["embroidary"].find((i: any) => {
      return i.view === "hood_backward";
    });

    /*if(editCartProduct){
      //const arrayE = this.createInitialStateForEdit(props.embroidaryOptions, editCartProduct);
      this.state = {
        embroidaryLocations: {
          front: {
            ...this.manipulateTheData(array, "front"),
          },
        },
      };
    } else {*/

      if (Boolean(frontObj.value)) {
        /*  || Object.keys(embroidaryView.front).length > 0 */
        /*console.log("frontObj.value", {
          embroidaryLocations: {
            front: {
              ...embroidaryView["front"],
            },
          },
        });*/
        this.state = {
          embroidaryLocations: {
            front: {
              ...embroidaryView["front"],
            },
          },
        };
      } else {
        /*console.log("else : ", {
          embroidaryLocations: {
            front: {
              ...this.manipulateTheData(array, "front"),
            },
          },
        });*/
        this.state = {
          embroidaryLocations: {
            front: {
              ...this.manipulateTheData(array, "front"),
            },
          },
        };
      }

      if (Boolean(backObj.value)) {
        this.state = {
          embroidaryLocations: {
            ...this.state.embroidaryLocations,
            back: {
              ...embroidaryView["back"],
            },
          },
        };
      } else {
        this.state = {
          embroidaryLocations: {
            ...this.state.embroidaryLocations,
            back: {
              ...this.manipulateTheData(array, "back"),
            },
          },
        };
      }

      if(arm_rightObj){
        if (Boolean(arm_rightObj.value)) {
          this.state = {
            embroidaryLocations: {
              ...this.state.embroidaryLocations,
              arm_right: {
                ...embroidaryView["arm_right"],
              },
            },
          };
        } else {
          this.state = {
            embroidaryLocations: {
              ...this.state.embroidaryLocations,
              arm_right: {
                ...this.manipulateTheData(array, "arm_right"),
              },
            },
          };
        }
      }

      if(arm_leftObj) {
        if (Boolean(arm_leftObj.value)) {
          this.state = {
            embroidaryLocations: {
              ...this.state.embroidaryLocations,
              arm_left: {
                ...embroidaryView["arm_left"],
              },
            },
          };
        } else {
          this.state = {
            embroidaryLocations: {
              ...this.state.embroidaryLocations,
              arm_left: {
                ...this.manipulateTheData(array, "arm_left"),
              },
            },
          };
        }
      }

      if(arm_rightCuffObj){
        if (Boolean(arm_rightCuffObj.value)) {
          this.state = {
            embroidaryLocations: {
              ...this.state.embroidaryLocations,
              cuff_right: {
                ...embroidaryView["cuff_right"],
              },
            },
          };
        } else {
          this.state = {
            embroidaryLocations: {
              ...this.state.embroidaryLocations,
              cuff_right: {
                ...this.manipulateTheData(array, "cuff_right"),
              },
            },
          };
        }
      }

      if(arm_leftCuffObj){
        if (Boolean(arm_leftCuffObj.value)) {
          this.state = {
            embroidaryLocations: {
              ...this.state.embroidaryLocations,
              cuff_left: {
                ...embroidaryView["cuff_left"],
              },
            },
          };
        } else {
          this.state = {
            embroidaryLocations: {
              ...this.state.embroidaryLocations,
              cuff_left: {
                ...this.manipulateTheData(array, "cuff_left"),
              },
            },
          };
        }
      }

      if(collarObj){
        if (Boolean(collarObj.value)) {
          this.state = {
            embroidaryLocations: {
              ...this.state.embroidaryLocations,
              on_collar: {
                ...embroidaryView["on_collar"],
              },
            },
          };
        } else {
          this.state = {
            embroidaryLocations: {
              ...this.state.embroidaryLocations,
              on_collar: {
                ...this.manipulateTheData(array, "on_collar"),
              },
            },
          };
        }
      }

      if(collarFlippedUpObj){
        if (Boolean(collarFlippedUpObj.value)) {
          this.state = {
            embroidaryLocations: {
              ...this.state.embroidaryLocations,
              collar_flipped_up: {
                ...embroidaryView["collar_flipped_up"],
              },
            },
          };
        } else {
          this.state = {
            embroidaryLocations: {
              ...this.state.embroidaryLocations,
              collar_flipped_up: {
                ...this.manipulateTheData(array, "collar_flipped_up"),
              },
            },
          };
        }
      }

      if(hoodObj){
        if (Boolean(hoodObj.value)) {
          this.state = {
            embroidaryLocations: {
              ...this.state.embroidaryLocations,
              hood: {
                ...embroidaryView["hood"],
              },
            },
          };
        } else {
          this.state = {
            embroidaryLocations: {
              ...this.state.embroidaryLocations,
              hood: {
                ...this.manipulateTheData(array, "hood"),
              },
            },
          };
        }
      }

      if(hoodForwardObj){
        if (Boolean(hoodForwardObj.value)) {
          this.state = {
            embroidaryLocations: {
              ...this.state.embroidaryLocations,
              hood_forward: {
                ...embroidaryView["hood_forward"],
              },
            },
          };
        } else {
          this.state = {
            embroidaryLocations: {
              ...this.state.embroidaryLocations,
              hood_forward: {
                ...this.manipulateTheData(array, "hood_forward"),
              },
            },
          };
        }
      }

      if(hoodBackwardObj){
        if (Boolean(hoodBackwardObj.value)) {
          this.state = {
            embroidaryLocations: {
              ...this.state.embroidaryLocations,
              hood_backward: {
                ...embroidaryView["hood_backward"],
              },
            },
          };
        } else {
          this.state = {
            embroidaryLocations: {
              ...this.state.embroidaryLocations,
              hood_backward: {
                ...this.manipulateTheData(array, "hood_backward"),
              },
            },
          };
        }
      }
    //}

    let sectionTotalPrice = 0;
    switch (selectedSide) {
      case "front":
        sectionTotalPrice = this.props.cartData.embroidaryFrontPageTotalPrice;
        break;

      case "back":
        sectionTotalPrice = this.props.cartData.embroidaryBackPageTotalPrice;
        break;

      case "arm_right":
        sectionTotalPrice = this.props.cartData.embroidaryRightPageTotalPrice;
        break;

      case "arm_left":
        sectionTotalPrice = this.props.cartData.embroidaryLeftPageTotalPrice;
        break;

      case "cuff_right":
        sectionTotalPrice = this.props.cartData.embroidaryRightCuffPageTotalPrice;
        break;

      case "cuff_left":
        sectionTotalPrice = this.props.cartData.embroidaryLeftCuffPageTotalPrice;
        break;

      case "on_collar":
        sectionTotalPrice = this.props.cartData.embroidaryCollarPageTotalPrice;
        break;

      case "collar_flipped_up":
        sectionTotalPrice = this.props.cartData.embroidaryCollarFlippedUpPageTotalPrice;
        break;

      case "hood":
        sectionTotalPrice = this.props.cartData.embroidaryHoodPageTotalPrice;
        break;

      case "hood_forward":
        sectionTotalPrice = this.props.cartData.embroidaryHoodForwardPageTotalPrice;
        break;

      case "hood_backward":
        sectionTotalPrice = this.props.cartData.embroidaryHoodBackwardPageTotalPrice;
        break;
    }

    /* console.log("sectionTotalPrice - updatePriceToCart"); */
    this.props.updatePriceToCart({
      totalPrice: sectionTotalPrice,
    });
    /* console.log(
      "this.props.cartInfo.cartData.totalPrice ========================= ",
      sectionTotalPrice
    ); */

    /*console.log(
      "this.state.embroidaryLocations : ",
      this.state.embroidaryLocations
    );*/
    this.props.updateEmbroidaryRedux(this.state.embroidaryLocations);

    /* update preview */
    const updatedArray = [];
    const emboidaryLoc: any = this.state.embroidaryLocations[selectedSide];
    for (let i in emboidaryLoc) {
      for (const [key, value] of Object.entries(emboidaryLoc[i])) {
        if ((value as any).checked) {
          //for preview
          const k = {};
          (k as any)[key] = this.convert(value, key);
          updatedArray.push(k);
          //end for preview
        }
      }
    }
    /* console.log("updatedArray : ", updatedArray); */
    this.updatePreview(updatedArray);

    this.updateEmbroidaryLocations = this.updateEmbroidaryLocations.bind(this);

    this.applyEmbroidary = this.applyEmbroidary.bind(this);

    //console.log("this.props.appliquePreviewData : ", this.props.appliquePreviewData);
  }

  componentDidMount(): void {
    categoryClient.getAllEmboidaryOptions().then((emboidaryOptionValues) => {
      /* console.log("emboidaryOptionValues : ", emboidaryOptionValues) */
      this.setState({ emboidaryOptionValues });
    });
  }

  componentDidUpdate(prevProps: any): void {
    if (prevProps.selectedSide !== this.props.selectedSide) {
      /* console.log("prevProps.selectedSide : ", prevProps.selectedSide, " this.props.selectedSide : ",this.props.selectedSide); */
      switch (prevProps.selectedSide) {
        case "front":
          this.props.updateEmbdryFrontPriceToCart({
            totalPrice: this.props.cartData.totalPrice
          });
          break;

        case "back":
          this.props.updateEmbdryBackPriceToCart({
            totalPrice: this.props.cartData.totalPrice
          });
          break;

        case "arm_right":
          this.props.updateEmbdryRightPriceToCart({
            totalPrice: this.props.cartData.totalPrice
          });
          break;

        case "arm_left":
          this.props.updateEmbdryLeftPriceToCart({
            totalPrice: this.props.cartData.totalPrice
          });
          break;

        case "cuff_right":
          this.props.updateEmbdryRightCuffPriceToCart({
            totalPrice: this.props.cartData.totalPrice
          });
          break;

        case "cuff_left":
          this.props.updateEmbdryLeftCuffPriceToCart({
            totalPrice: this.props.cartData.totalPrice
          });
          break;
          
        case "on_collar":
          this.props.updateEmbdryCollarPriceToCart({
            totalPrice: this.props.cartData.totalPrice
          });
          break;  

        case "collar_flipped_up":
          this.props.updateEmbdryCollarFlippedUpPriceToCart({
            totalPrice: this.props.cartData.totalPrice
          });
          break;

        case "hood":
          this.props.updateEmbdryHoodPriceToCart({
            totalPrice: this.props.cartData.totalPrice
          });
          break;

        case "hood_forward":
          this.props.updateEmbdryHoodForwardPriceToCart({
            totalPrice: this.props.cartData.totalPrice
          });
          break;

        case "hood_backward":
          this.props.updateEmbdryHoodBackwardPriceToCart({
            totalPrice: this.props.cartData.totalPrice
          });
          break;
      }

      let sectionTotalPrice = 0;
      switch (this.props.selectedSide) {
        case "front":
          sectionTotalPrice = this.props.cartData.embroidaryFrontPageTotalPrice;
          break;

        case "back":
          sectionTotalPrice = this.props.cartData.embroidaryBackPageTotalPrice;
          break;

        case "arm_right":
          sectionTotalPrice = this.props.cartData.embroidaryRightPageTotalPrice;
          break;

        case "arm_left":
          sectionTotalPrice = this.props.cartData.embroidaryLeftPageTotalPrice;
          break;

        case "cuff_right":
          sectionTotalPrice = this.props.cartData.embroidaryRightCuffPageTotalPrice;
          break;

        case "cuff_left":
          sectionTotalPrice = this.props.cartData.embroidaryLeftCuffPageTotalPrice;
          break;

        case "on_collar":
          sectionTotalPrice = this.props.cartData.embroidaryCollarPageTotalPrice;
          break;

        case "collar_flipped_up":
          sectionTotalPrice = this.props.cartData.embroidaryCollarFlippedUpPageTotalPrice;
          break;

        case "hood":
          sectionTotalPrice = this.props.cartData.embroidaryHoodPageTotalPrice;
          break;

        case "hood_forward":
          sectionTotalPrice = this.props.cartData.embroidaryHoodForwardPageTotalPrice;
          break;

        case "hood_backward":
          sectionTotalPrice = this.props.cartData.embroidaryHoodBackwardPageTotalPrice;
          break;
      }

      /* console.log("componentDidUpdate - sectionTotalPrice - updatePriceToCart"); */
      this.props.updatePriceToCart({
        totalPrice: sectionTotalPrice,
      });

      const obj = this.props.designViews["embroidary"].find((i: any) => {
        return i.view === this.props.selectedSide;
      });
      if (obj) {
        if (Boolean(obj.value)) {
          this.state = {
            embroidaryLocations: {
              [this.props.selectedSide]: {
                ...this.props.embroidaryView[this.props.selectedSide],
              },
            },
          };
        }
      }
    }
  }

  convertToPreviewSideValue(side: string) {
    let sideVal = side.trim().toLowerCase().replace(/\s/g, "_");
    //console.log("sideVal : ", sideVal);
    let actualSideVal = "";
    switch (sideVal) {
      case "front":
        actualSideVal = "front";
        break;
      case "back":
        actualSideVal = "back";
        break;
      case "chest":
        actualSideVal = "front";
        break;
      case "left_chest":
        actualSideVal = "front";
        break;
      case "right_chest":
        actualSideVal = "front";
        break;
      case "left_arm":
        actualSideVal = "arm_left";
        break;
      case "right_arm":
        actualSideVal = "arm_right";
        break;
      case "left_cuff":
        actualSideVal = "cuff_left";
        break;
      case "right_cuff":
        actualSideVal = "cuff_right";
        break;
      case "on_collar":
        actualSideVal = "on_collar";
        break;        
      case "collar_flipped_up":
        actualSideVal = "collar_flipped_up";
        break;
      case "hood":
        actualSideVal = "hood";
        break;  
      case "hood_forward":
        actualSideVal = "hood_forward";
        break;        
      case "hood_backward":
        actualSideVal = "hood_backward";
        break;
      default:
        actualSideVal = "";
        break;
    }

    return actualSideVal;
  }

  manipulateTheData(array: any, side: string) {
    const finalReturn: any = [];
    array.forEach((d: any) => {
      //if (side.includes(d.side)) {
      let actualSideVal = this.convertToPreviewSideValue(d.side);
      //console.log("actualSideVal == side", actualSideVal, side);
      if (actualSideVal == side) {
        finalReturn.push(d.data);
      }
    });
    return Object.assign({}, ...finalReturn);
  }

  // manipulateTheData(array: any, side: any) {
  //   debugger;
  //   const finalReturn: any = [];
  //   array.forEach((d: any) => {
  //     if (side.includes(d.name)) {
  //       finalReturn.push(d.data);
  //     }
  //   });
  //   return Object.assign({}, ...finalReturn);
  // }

  checkNumber(str: string) {
    return !/\d/.test(str);
  }

  createInitialState(embroidaryOptions: any) {
    const convertedData = [];
    for (let t of embroidaryOptions) {
        let c = {
          [t._id]: {
            ...t.keys,
          },
        };
        console.log("c : ", c);
        for (const [key, value] of Object.entries(t.keys)) {
          c[t._id][key] = {};
          (value as any).forEach((d: any) => {
            //console.log('d : ', d);
            if (d.inputType === "select") {
              c[t._id][key][d.name] = { label: `Select ${d.label}`, value: "" };
            } else if (d.inputType === "text") {
              c[t._id][key][d.name] = "";
            }
            c[t._id][key]["checked"] = false;
          });
        }
        convertedData.push({
          data: c,
          name: t.name.toLowerCase(),
          side: t.location,
        });
      //}
    }
    return convertedData;
  }

  createInitialStateForEdit(embroidaryOptions: any, editOpt : any) {
    const convertedData = [];
    for (let t of embroidaryOptions) {
        let c = {
          [t._id]: {
            ...t.keys,
          },
        };

        for (const [key, value] of Object.entries(t.keys)) {
          c[t._id][key] = {};
          (value as any).forEach((d: any) => {
            console.log('d : ', d);
            if (d.inputType === "select") {
              c[t._id][key][d.name] = { label: `Select ${d.label}`, value: "" };
            } else if (d.inputType === "text") {
              c[t._id][key][d.name] = "";
            }
            c[t._id][key]["checked"] = false;
          });
        }
        convertedData.push({
          data: c,
          name: t.name.toLowerCase(),
          side: t.location,
        });
      //}
    }
    return convertedData;
  }

  updateEmbroidaryLocations(emboidaryLoc: any, callback: any) {
    this.setState((prevState, props) => {
      //console.log("prevState : ", prevState);
      return {
        embroidaryLocations: {
          ...this.state.embroidaryLocations,
          ...emboidaryLoc,
        },
      }
    },
      callback
    );
  }

  updateCartEmbroidaryData(values: any) {
    const { emboidaryOptionValues } = this.state;
    const keys: any = {};

    for (let [key, value] of Object.entries(values)) {
      if (key === "fontSize") {
        const selectedFontSize = emboidaryOptionValues[key].filter((i: any) => {
          return i._id === (value as any).value;
        })[0];

        if (selectedFontSize) keys["fontSize"] = selectedFontSize;
      } else if (key === "fontStyles") {
        const selectedFontStyle = emboidaryOptionValues["fontStyles"].filter(
          (i: any) => {
            return i._id === (value as any).value;
          }
        )[0];
        if (selectedFontStyle) keys["fontStyle"] = selectedFontStyle;
      } else if (
        /* key === "textLine1" ||
        key === "textLine2" ||
        key === "textLine3" ||
        key === "text" */
        key.includes("text") && !key.includes("_actual")
      ) {
        keys[key] = {
          value: value,
        };
      } else if (key === "threadToMatchs") {
        const selectedThread = emboidaryOptionValues["threadToMatchs"].filter(
          (i: any) => {
            return i.value === (value as any).value;
          }
        )[0];
        if (selectedThread) keys["threadColor"] = selectedThread;
      } else if (key === "design") {
        const selectedDesign = emboidaryOptionValues["design"].filter(
          (i: any) => {
            return i.label === (value as any).label;
          }
        )[0];
        if (selectedDesign) keys["design"] = selectedDesign;
      } else if (key === "imageSize") {
        const selectedImageSize = emboidaryOptionValues["imageSize"].filter(
          (i: any) => {
            return i._id === (value as any).value;
          }
        )[0];
        if (selectedImageSize) keys["imageSize"] = selectedImageSize;
      } else if (key === "embroidaryLocation") {
        const selectedEmbroidaryLocation = emboidaryOptionValues[
          "embroidaryLocation"
        ].filter((i: any) => {
          return i._id === (value as any).value;
        })[0];
        if (selectedEmbroidaryLocation)
          keys["embroidaryLocation"] = selectedEmbroidaryLocation;
      }
    }
    /* console.log("updateCartEmbroidaryData keys : ", keys); */
    return keys;
  }

  addFontSizePriceToCart(price: number) {
    /* console.log("addFontSizePriceToCart - addPriceToCart"); */
    this.props.addPriceToCart({
      price,
    });
  }

  removeFontSizePriceFromCart(price: number){
    /* console.log("removeFontSizePriceFromCart - removePriceToCart"); */
    this.props.removePriceToCart({
      price
    })
  }

  /* embroidary calculation */
  calculatePrice(key: any, values: any, embroidaryOption: any) {
    const { emboidaryOptionValues, embroidaryLocations } = this.state;
    /* console.log("embroidaryOption : ", embroidaryOption);
    console.log("embroidaryLocations : ", embroidaryLocations);
    console.log("emboidaryOptionValues : ", emboidaryOptionValues); */
    /*console.log(
      key,
      values,
      embroidaryOption,
      emboidaryOptionValues,
      "key, value, embroidaryOption, emboidaryOptionValues",
      this.cartEmbroidaryData,
      "this.cartEmbroidaryData"
    );*/

    if (Object.keys(embroidaryOption.keys).includes(key)) {
      //Note - Issue when selects Crest and Images
      if (values["fontSize"]) { //text
        const selectedFontSize = emboidaryOptionValues["fontSize"].find(
          (i: any) => {
            return i._id === values["fontSize"].value;
          }
        );
        const selectedthreadToMatchs = emboidaryOptionValues["threadToMatchs"].find(
          (i: any) => {
            return i.value === values["threadToMatchs"].value;
          }
        ); 
        //base price
        const selectedTextPrice = embroidaryOption.basePrice.find(
          (i: any) => {
            return i.name === key;
          }
        );
        const letterSize = Number(
          selectedFontSize.name.split(" ")[1].split("(")[1]
        );
        let enteredTextSize = 0;
        let enteredTextSizeTextLine2 = 0;
        let enteredTextSizeTextLine3 = 0;
        if(values.textLine1){
          enteredTextSize = Number(values.textLine1.length);
        }
        if (values.textLine2) {
          enteredTextSizeTextLine2 = Number(values.textLine2.length);
        }
        if (values.textLine3) {
          enteredTextSizeTextLine3 = Number(values.textLine3.length);
        }

        if (this.cartEmbroidaryData.length > 0) {
          const aa = this.cartEmbroidaryData.find((i: any) => {
            //return i.name === embroidaryOption.name;
            return i._id === embroidaryOption._id;
          });
          const index = this.cartEmbroidaryData.findIndex((i: any) => {
            return i._id === embroidaryOption._id;
          });
          if (aa) {
            /* if cart embroidary data exists */
            if (aa["keys"][key] && Object.keys(aa["keys"][key]).length > 0) {

              const selectedKeyLength =
                aa["keys"][key]["textLine1"].value.length;
              let selectedKeyLengthtextLine2 = 0;
              let selectedKeyLengthtextLine3 = 0;
              if (aa["keys"][key]["textLine2"]) {
                selectedKeyLengthtextLine2 =
                  aa["keys"][key]["textLine2"].value.length;
              }
              if (aa["keys"][key]["textLine3"]) {
                selectedKeyLengthtextLine3 =
                  aa["keys"][key]["textLine3"].value.length;
              }

              let diff = 0;

              //decrease
              if (selectedKeyLength > enteredTextSize) {
                if (
                  enteredTextSize < letterSize &&
                  selectedKeyLength < letterSize
                ) {
                  return;
                }
                
                if (enteredTextSize < letterSize) {
                  diff = selectedKeyLength - letterSize;
                  const price =
                    this.props.totalQuantity * diff * selectedFontSize.price;
                  this.removeFontSizePriceFromCart(price);
                } else {
                  diff = selectedKeyLength - enteredTextSize;
                  const price =
                    this.props.totalQuantity * diff * selectedFontSize.price;
                  this.removeFontSizePriceFromCart(price);
                }
              }

              
              //increses
              if (selectedKeyLength < enteredTextSize) {
                if (enteredTextSize < letterSize) {
                  return;
                }
                //let diff = 0;
                if (selectedKeyLength < letterSize) {
                  diff = enteredTextSize - letterSize;
                } else {
                  diff = enteredTextSize - selectedKeyLength;
                }
                const price =
                  this.props.totalQuantity * diff * selectedFontSize.price;
                this.addFontSizePriceToCart(price);
              }

              let diff2 = 0;
              //for textline2
              if (selectedKeyLengthtextLine2) {
                //decrease
                if (selectedKeyLengthtextLine2 > enteredTextSizeTextLine2) {
                  //let diff = 0;
                  if (enteredTextSizeTextLine2 < letterSize) {
                    diff2 = selectedKeyLengthtextLine2 - letterSize;
                    const price =
                      this.props.totalQuantity * diff2 * selectedFontSize.price;
                    this.removeFontSizePriceFromCart(price);
                  } else {
                    diff2 =
                      selectedKeyLengthtextLine2 - enteredTextSizeTextLine2;
                    const price =
                      this.props.totalQuantity * diff2 * selectedFontSize.price;
                    this.removeFontSizePriceFromCart(price);
                  }
                }

                //increses
                if (selectedKeyLengthtextLine2 < enteredTextSizeTextLine2) {
                  if (enteredTextSizeTextLine2 < letterSize) {
                    return;
                  }
                  //let diff = 0;
                  if (selectedKeyLengthtextLine2 < letterSize) {
                    diff2 = enteredTextSizeTextLine2 - letterSize;
                  } else {
                    diff2 =
                      enteredTextSizeTextLine2 - selectedKeyLengthtextLine2;
                  }
                  const price =
                    this.props.totalQuantity * diff2 * selectedFontSize.price;
                  this.addFontSizePriceToCart(price);
                }
              }

              let diff3 = 0;
              //for textline3
              if (selectedKeyLengthtextLine3) {
                //decrease
                if (selectedKeyLengthtextLine3 > enteredTextSizeTextLine3) {
                  //let diff = 0;
                  if (enteredTextSizeTextLine3 < letterSize) {
                    diff3 = selectedKeyLengthtextLine3 - letterSize;
                    const price =
                      this.props.totalQuantity * diff3 * selectedFontSize.price;
                    this.removeFontSizePriceFromCart(price);
                  } else {
                    diff3 =
                      selectedKeyLengthtextLine3 - enteredTextSizeTextLine3;
                    const price =
                      this.props.totalQuantity * diff3 * selectedFontSize.price;
                    this.removeFontSizePriceFromCart(price);
                  }
                }

                //increses
                if (selectedKeyLengthtextLine3 < enteredTextSizeTextLine3) {
                  if (enteredTextSizeTextLine3 < letterSize) {
                    return;
                  }
                  //let diff = 0;
                  if (selectedKeyLengthtextLine3 < letterSize) {
                    diff3 = enteredTextSizeTextLine3 - letterSize;
                  } else {
                    diff3 =
                      enteredTextSizeTextLine3 - selectedKeyLengthtextLine3;
                  }
                  const price =
                    this.props.totalQuantity * diff3 * selectedFontSize.price;
                  this.addFontSizePriceToCart(price);
                }
              }

              this.cartEmbroidaryData[index] = {
                ...aa,
                diff: diff+diff2+diff3
              };

              /* remove last color */
              let price = this.props.totalQuantity * (aa["keys"][key].threadColor.price);
              this.props.removePriceToCart({
                price: price
              });

              /* add color */
              const colorPrice = this.props.totalQuantity * (selectedthreadToMatchs.price);
              this.props.addPriceToCart({
                price: colorPrice
              });
            } else { // embroidary type not exists
              /* add base price */
              const basePrice = this.props.totalQuantity * (selectedTextPrice.price?selectedTextPrice.price:0);
              this.props.addPriceToCart({
                price: basePrice
              });

              const diff = enteredTextSize - letterSize;
              const price =
                this.props.totalQuantity * diff * selectedFontSize.price;
              if (diff > 0) {
                this.addFontSizePriceToCart(price);
              }
              if (enteredTextSizeTextLine2) {
                let diff = enteredTextSizeTextLine2 - letterSize;
                const price =
                  this.props.totalQuantity * diff * selectedFontSize.price;
                if (diff > 0) {
                  this.addFontSizePriceToCart(price);
                }
              }
              if (enteredTextSizeTextLine3) {
                let diff = enteredTextSizeTextLine3 - letterSize;
                const price =
                  this.props.totalQuantity * diff * selectedFontSize.price;
                if (diff > 0) {
                  this.addFontSizePriceToCart(price);
                }
              }

              /* add color */
              const colorPrice = this.props.totalQuantity * (selectedthreadToMatchs.price);
              this.props.addPriceToCart({
                price: colorPrice
              });
            }
          } else {
            /* cart data exists but option name not exists */
            /* add base price */
            const basePrice = this.props.totalQuantity * (selectedTextPrice.price?selectedTextPrice.price:0);
            this.props.addPriceToCart({
              price: basePrice
            });

            const diff = enteredTextSize - letterSize;
            const price =
              this.props.totalQuantity * diff * selectedFontSize.price;
            if (diff > 0) {
              this.addFontSizePriceToCart(price);
            }
            if (enteredTextSizeTextLine2) {
              let diff = enteredTextSizeTextLine2 - letterSize;
              const price =
                this.props.totalQuantity * diff * selectedFontSize.price;
              if (diff > 0) {
                this.addFontSizePriceToCart(price);
              }
            }
            if (enteredTextSizeTextLine3) {
              let diff = enteredTextSizeTextLine3 - letterSize;
              const price =
                this.props.totalQuantity * diff * selectedFontSize.price;
              if (diff > 0) {
                this.addFontSizePriceToCart(price);
              }
            }

            /* add color */
            const colorPrice = this.props.totalQuantity * (selectedthreadToMatchs.price);
            this.props.addPriceToCart({
              price: colorPrice
            });
          }
        } else { /* cart data not exists */
          /* add base price */
          const basePrice = this.props.totalQuantity * (selectedTextPrice.price?selectedTextPrice.price:0);
          this.props.addPriceToCart({
            price: basePrice
          });
          
          const diff = enteredTextSize - letterSize;
          const price =
            this.props.totalQuantity * diff * selectedFontSize.price;
          if (diff > 0) {
            this.addFontSizePriceToCart(price);
          }
          if (enteredTextSizeTextLine2) {
            let diff = enteredTextSizeTextLine2 - letterSize;
            const price =
              this.props.totalQuantity * diff * selectedFontSize.price;
            if (diff > 0) {
              this.addFontSizePriceToCart(price);
            }
          }
          if (enteredTextSizeTextLine3) {
            let diff = enteredTextSizeTextLine3 - letterSize;
            const price =
              this.props.totalQuantity * diff * selectedFontSize.price;
            if (diff > 0) {
              this.addFontSizePriceToCart(price);
            }
          }

          /* add color */
          const colorPrice = this.props.totalQuantity * (selectedthreadToMatchs.price);
          this.props.addPriceToCart({
            price: colorPrice
          });
        }
      } else {
        //crest and image case
        const selectedImageSize = emboidaryOptionValues["imageSize"].find(
          (i: any) => {
            return i._id === values["imageSize"].value;
          }
        );

        //base price
        const selectedImageBasePrice = embroidaryOption.basePrice.find(
          (i: any) => {
            return i.name === key;
          }
        );

        // this.props.addPriceToCart({
        //   price: selectedImageSize.price,
        // });
        if (this.cartEmbroidaryData.length === 0) {
          /* console.log("calculatePrice - selectedImageSize1 - addPriceToCart"); */
          this.props.addPriceToCart({
            price: this.props.totalQuantity * (selectedImageBasePrice.price + selectedImageSize.price),
          });
        } else {
          /* if cartEmbroidary data exists */
          const index = this.cartEmbroidaryData.findIndex((i: any) => {
            return i._id === embroidaryOption._id;
          });
          if (index !== -1) {
            /* if Embroidary option data exists */
            if (
              !Object.keys(this.cartEmbroidaryData[index].keys).includes(key)
            ) { // Embroidary option type not exists
              /* console.log(
                "calculatePrice - selectedImageSize - addPriceToCart"
              ); */
              this.props.addPriceToCart({
                price: this.props.totalQuantity * (selectedImageBasePrice.price + selectedImageSize.price),
              });
            } else { // Embroidary option type exists
              const aa = this.cartEmbroidaryData.find((i: any) => {
                //return i.name === embroidaryOption.name;
                return i._id === embroidaryOption._id;
              });
              
              /* embroidary option exists */
              if (aa["keys"][key]["imageSize"]) { 
                let nam = aa["keys"][key]["imageSize"]["name"];
                let pr = aa["keys"][key]["imageSize"]["price"];
                //console.log("nam : ",nam," price : ",pr);
  
                /* remove last price */
                const price = this.props.totalQuantity * (selectedImageBasePrice.price + aa["keys"][key].imageSize.price);
                this.props.removePriceToCart({
                  price: price
                });
              }

              /* console.log(
                "calculatePrice - selectedImageSize - addPriceToCart"
              ); */
              this.props.addPriceToCart({
                price: this.props.totalQuantity * (selectedImageBasePrice.price + selectedImageSize.price),
              });
            }
          } else {
            /* console.log("calculatePrice - selectedImageSize3 - addPriceToCart"); */
            this.props.addPriceToCart({
              price: this.props.totalQuantity * (selectedImageBasePrice.price + selectedImageSize.price),
            });
          }
        }

        // if (index !== -1) {
        //   let aa = this.cartEmbroidaryData.find((i: any) => {
        //     return i._id === embroidaryOption._id;
        //   });
        //   if (!aa["keys"][key]) {
        //     this.props.addPriceToCart({
        //       price: selectedImageSize.price,
        //     });
        //   }
        // }
      }
    }
  }

  convert(values: any, typeK:string) {
    const { emboidaryOptionValues } = this.state;
    let k:any = [];
    for (let [key, value] of Object.entries(values)) {
      var obj: any = {};
      obj["settingVal"] = value;
      if (key === "fontSize") {
        if ((value as any).value === "") {
          obj["settingVal"] = { value: 1 };
        } else {
          obj["settingVal"] = { value: (value as any).label.split('"')[0] };
        }
        key = PreviewSettingNames.letterSize;
      } else if (key === "fontStyles") {
        if ((value as any).value === "" && typeof emboidaryOptionValues !== "undefined") {
          const { name, _id } = (emboidaryOptionValues as any)[key][0];
          obj["settingVal"] = { value: { label: name, value: _id } };
        } else {
          obj["settingVal"] = { value };
        }
        key = PreviewSettingNames.fontFamily;
      } else if (
        /* key === "textLine1" ||
        key === "textLine2" ||
        key === "textLine3" */
        key.includes("text") && !key.includes("_actual")
      ) {
        const selectedK = k.find((i: any) => {
          return i.settingName === PreviewSettingNames.contentText;
        });

        if (selectedK) {
          key = PreviewSettingNames.contentText;
          const selectedKIndex = k.findIndex((i: any) => {
            return i.settingName === PreviewSettingNames.contentText;
          });
          const newVal = selectedK.settingVal.value + '\n' + value;
          obj["settingVal"] = { value: newVal };

          k.splice(selectedKIndex, 1, obj);
        } else {
          key = PreviewSettingNames.contentText;
          obj["settingVal"] = { value: typeK == "Hood_Forward"?flippedText(value):value};
        }
      } else if (key === "threadToMatchs") {
        key = PreviewSettingNames.backGroundFillStrokeMarker;
      }
      obj["settingName"] = key;
      k.push(obj);
    }
    return k;
  }

  applyEmbroidary(emboidaryLoc: any, embroidaryOption: any) {
    /* console.log("emboidaryLoc :++ ", emboidaryLoc)
    console.log("embroidaryOption : ", embroidaryOption);
    console.log("this.cartEmbroidaryData : ", this.cartEmbroidaryData); */

    let j = {};
    const updatedArray = [];
    for (let i in emboidaryLoc) {
      for (const [key, value] of Object.entries(emboidaryLoc[i])) {
        if ((value as any).checked) {
          //calculate price (add and update)
          this.calculatePrice(key, value, embroidaryOption);
          if (this.cartEmbroidaryData.length === 0) {
            j = {
              ...embroidaryOption,
              keys: {
                ...(j as any)["keys"],
                [key]: this.updateCartEmbroidaryData(value),
              },
            };
            this.cartEmbroidaryData.push(j);
          } else {
            const index = this.cartEmbroidaryData.findIndex((i: any) => {
              return i._id === embroidaryOption._id;
            });
            if (index !== -1) {
              let aa = this.cartEmbroidaryData.find((i: any) => {
                return i._id === embroidaryOption._id;
              });
              if (aa) {
                if (Object.keys(embroidaryOption.keys).includes(key)) {
                  aa = {
                    basePrice: aa["basePrice"],
                    images: aa["images"],
                    name: aa["name"],
                    _id: aa["_id"],
                    keys: {
                      ...(aa as any)["keys"],
                      [key]: this.updateCartEmbroidaryData(value),
                    },
                  };
                  this.cartEmbroidaryData[index] = aa;
                }
              }
            } else {
              if (Object.keys(embroidaryOption.keys).includes(key)) {
                j = {
                  basePrice: embroidaryOption["basePrice"],
                  images: embroidaryOption["images"],
                  name: embroidaryOption["name"],
                  _id: embroidaryOption["_id"],
                  keys: {
                    [key]: this.updateCartEmbroidaryData(value),
                  },
                };
                this.cartEmbroidaryData.push(j);
              }
            }
          }

          //for preview
          const k = {};
          (k as any)[key] = this.convert(value, key);
          updatedArray.push(k);
          //end for preview
        } else { /* if unchacked */
          //calculate price (delete)
          if (this.cartEmbroidaryData.length > 0) {
            //remove the font price from cart when unchecked
            const aa = this.cartEmbroidaryData.find((i: any) => {
              //return i.name === embroidaryOption.name;
              return i._id === embroidaryOption._id
            });
            if (aa) {
              if (aa["keys"][key] && Object.keys(aa["keys"][key]).length > 0) {
                if (aa["keys"][key]["textLine1"]) { /* text */
                  const selectedKeyLength =
                    aa["keys"][key]["textLine1"].value.length;
                  let selectedKeyLengthtextLine2 = 0;
                  let selectedKeyLengthtextLine3 = 0;
                  if (aa["keys"][key]["textLine2"]) {
                    selectedKeyLengthtextLine2 =
                      aa["keys"][key]["textLine2"].value.length;
                  }
                  if (aa["keys"][key]["textLine3"]) {
                    selectedKeyLengthtextLine3 =
                      aa["keys"][key]["textLine3"].value.length;
                  }

                  const letterSize = Number(
                    aa["keys"][key]["fontSize"].name.split(" ")[1].split("(")[1]
                  );
                  const diff = selectedKeyLength - letterSize;
                  const price =
                    this.props.totalQuantity *
                    diff *
                    aa["keys"][key]["fontSize"].price;
                  if (diff > 0) {
                    this.removeFontSizePriceFromCart(price);
                  }

                  if (selectedKeyLengthtextLine2) {
                    let diff = selectedKeyLengthtextLine2 - letterSize;
                    const price =
                      this.props.totalQuantity *
                      diff *
                      aa["keys"][key]["fontSize"].price;
                    if (diff > 0) {
                      this.removeFontSizePriceFromCart(price);
                    }
                  }
                  if (selectedKeyLengthtextLine3) {
                    let diff = selectedKeyLengthtextLine3 - letterSize;
                    const price =
                      this.props.totalQuantity *
                      diff *
                      aa["keys"][key]["fontSize"].price;
                    if (diff > 0) {
                      this.removeFontSizePriceFromCart(price);
                    }
                  }

                  //base price
                  const selectedTextPrice = embroidaryOption.basePrice.find(
                    (i: any) => {
                      return i.name === key;
                    }
                  );

                  /* remove base price */
                  const basePrice = this.props.totalQuantity * (selectedTextPrice.price);
                  this.props.removePriceToCart({
                    price: basePrice
                  });

                  /* remove last color */
                  const colorPrice = this.props.totalQuantity * (aa["keys"][key].threadColor.price);
                  this.props.removePriceToCart({
                    price: colorPrice
                  });
                } else { /* crest and images */
                  //console.log("applyEmbroidary - removePriceToCart");
                  //base price
                  const selectedImageBasePrice = embroidaryOption.basePrice.find(
                    (i: any) => {
                      return i.name === key;
                    }
                  );
                  const price = this.props.totalQuantity * (selectedImageBasePrice.price + aa["keys"][key].imageSize.price);
                  this.props.removePriceToCart({
                    price: price
                  });
                }
              }
            }

            //end
            const index = this.cartEmbroidaryData.findIndex((i: any) => {
              return i._id === embroidaryOption._id;
            });
            if (index !== -1) {
              let aa = this.cartEmbroidaryData.find((i: any) => {
                return i._id === embroidaryOption._id;
              });
              delete aa["keys"][key];
            }
          }
        }
      }
    }

    this.props.updateEmbroidaryRedux({
      ...this.state.embroidaryLocations,
      [this.props.selectedSide]: {
        ...emboidaryLoc,
      },
    });
    this.updatePreview(updatedArray);
  }

  updatePreview(array: any) {
    this.props.updatePreview(array, DesignerViewStates.EmboideryLocations);
  }

  embroidaryNext = () => {
    this.props.updateEmbroidaryRedux(this.state.embroidaryLocations);
    /*console.log(
      this.cartEmbroidaryData,
      "cartEmbroidaryData",
      this.state.embroidaryLocations,
      this.props.embroidaryView
    );*/
    this.props.updateEmbroidaryToCart(this.cartEmbroidaryData);
    this.props.onNext(this.cartEmbroidaryData);
  };

  render(): ReactNode {
    const {
      selectedEmbroidaryOption,
      embroidaryLocations,
      emboidaryOptionValues,
    } = this.state;
    /* console.log("selectedEmbroidaryOption : ", selectedEmbroidaryOption); */

    const { onNext, onBack, embroidaryOptions, appliqueView } = this.props;

    /* console.log("embroidaryOptions : ", embroidaryOptions);
    console.log("emboidaryOptionValues : ", emboidaryOptionValues);

    console.log("this.props.appliqueView :::::::: ", appliqueView); */
    return (
      <>
        <MainContainer>
          <Wrapper>
            <SettingHeader>
              <TextBeta className="bold">{`THREAD EMBROIDERY : ${pascalCase(displayEmbdName(this.props.selectedSide))}`}</TextBeta>
            </SettingHeader>
            <TextTheta>Customize one or more locations.</TextTheta>
          </Wrapper>

          <EmbroiederyWrapper>
            {embroidaryOptions.map((option, i) => {
              const selectedAppliqueContextText =
                this.props.appliquePreviewData.find((i: any) => {
                  return i.settingName === PreviewSettingNames.contentText;
                });
              const sideVal = this.convertToPreviewSideValue(option.location);  
              if (
                sideVal != "" 
                && 
                /* this.props.selectedSide.includes(
                  sideVal
                ) */
                this.props.selectedSide == sideVal
              ) {
                if (
                  selectedAppliqueContextText &&
                  selectedAppliqueContextText.settingVal.value.trim() === "" &&
                  option.name === "Additional Text to Greek Letters"
                ) {
                  return;
                }
                if(this.props.selectedSide == "back" && typeof appliqueView[this.props.selectedSide].isCenterApplique !== "undefined" 
                  && appliqueView[this.props.selectedSide].isCenterApplique == true 
                  && typeof appliqueView[this.props.selectedSide].selectedNumber !== "undefined" 
                  && appliqueView[this.props.selectedSide].selectedNumber.length > 0
                  && option.name === "Additional Text to Greek Letters"
                ){
                  return;
                }
                return (
                  <>
                    <OptionButton
                      key={i}
                      onClick={() =>
                        this.setState({
                          selectedEmbroidaryOption: option,
                        })
                      }
                    >
                      {option.name ==="Crest and Images(price varies with size and design)"?"Crest and Images ( Price Varies With Size And Design)":option.name}
                    </OptionButton>
                  </>
                );
              }
            })}
            <SecondaryContainer></SecondaryContainer>
          </EmbroiederyWrapper>

          {/*<WrapperForAgreement>
            <div style={{ width: 70 }}>
              <StyeledCheckbox></StyeledCheckbox>
            </div>
            <TextTheta>
              I have reviewed and hereby confirmed that my customization are
              correct in the Order Summary, located to the right of this page.
              The order will be produced using our current process. Your MUST
              notify us if you are trying to match a previous order or something
              made by another company.
            </TextTheta>
          </WrapperForAgreement>*/}

          <Wrapper>
            <Divider></Divider>
            <CtaContainer>
              <ClearStepBtn onClick={onBack}>Previous</ClearStepBtn>
              <NextpBtn onClick={() => this.embroidaryNext()}>Next</NextpBtn>
            </CtaContainer>
          </Wrapper>
        </MainContainer>
        <AdditionalDesignModal
          isOpen={!!selectedEmbroidaryOption}
          emboidaryOptionValues={emboidaryOptionValues}
          embroidaryOption={selectedEmbroidaryOption}
          embroidaryLocations={embroidaryLocations}
          updateEmbroidaryLocations={this.updateEmbroidaryLocations}
          close={() => this.setState({ selectedEmbroidaryOption: undefined })}
          selectedSide={this.props.selectedSide}
          selectedColor={this.props.selectedColor}
          appliquePreviewData={this.props.appliquePreviewData}
          onDone={(emboidaryLoc, embroidaryOption) => {
            this.applyEmbroidary(emboidaryLoc, embroidaryOption);

            /*console.log(
              emboidaryLoc,
              embroidaryOption,
              "emboidaryLoc, embroidaryOption"
            );
            let j = {};
            const updatedArray = [];
            for (let i in emboidaryLoc) {
              for (const [key, value] of Object.entries(emboidaryLoc[i])) {
                console.log("key : ", key, ", value :  ", value);
                if ((value as any).checked) {
                  //calculate price
                  this.calculatePrice(key, value, embroidaryOption);
                  if (this.cartEmbroidaryData.length === 0) {
                    j = {
                      ...embroidaryOption,
                      keys: {
                        ...(j as any)["keys"],
                        [key]: this.updateCartEmbroidaryData(value),
                      },
                    };
                    this.cartEmbroidaryData.push(j);
                  } else {
                    const index = this.cartEmbroidaryData.findIndex(
                      (i: any) => {
                        return i._id === embroidaryOption._id;
                      }
                    );
                    if (index !== -1) {
                      let aa = this.cartEmbroidaryData.find((i: any) => {
                        return i._id === embroidaryOption._id;
                      });
                      if (aa) {
                        if (Object.keys(embroidaryOption.keys).includes(key)) {
                          aa = {
                            basePrice: aa["basePrice"],
                            images: aa["images"],
                            name: aa["name"],
                            _id: aa["_id"],
                            keys: {
                              ...(aa as any)["keys"],
                              [key]: this.updateCartEmbroidaryData(value),
                            },
                          };
                          this.cartEmbroidaryData[index] = aa;
                        }
                      }
                    } else {
                      if (Object.keys(embroidaryOption.keys).includes(key)) {
                        j = {
                          basePrice: embroidaryOption["basePrice"],
                          images: embroidaryOption["images"],
                          name: embroidaryOption["name"],
                          _id: embroidaryOption["_id"],
                          keys: {
                            [key]: this.updateCartEmbroidaryData(value),
                          },
                        };
                        this.cartEmbroidaryData.push(j);
                      }
                    }
                  }

                  //for preview
                  const k = {};
                  (k as any)[key] = this.convert(value);
                  updatedArray.push(k);
                  //end for preview
                } else {
                  if (this.cartEmbroidaryData.length > 0) {
                    //remove the font price from cart when unchecked
                    const aa = this.cartEmbroidaryData.find((i: any) => {
                      //return i.name === embroidaryOption.name;
                      return i._id === embroidaryOption._id;
                    });
                    if (aa) {
                      if (
                        aa["keys"][key] &&
                        Object.keys(aa["keys"][key]).length > 0
                      ) {
                        if (aa["keys"][key]["textLine1"]) {
                          const selectedKeyLength =
                            aa["keys"][key]["textLine1"].value.length;
                          let selectedKeyLengthtextLine2 = 0;
                          let selectedKeyLengthtextLine3 = 0;
                          if (aa["keys"][key]["textLine2"]) {
                            selectedKeyLengthtextLine2 =
                              aa["keys"][key]["textLine2"].value.length;
                          }
                          if (aa["keys"][key]["textLine3"]) {
                            selectedKeyLengthtextLine3 =
                              aa["keys"][key]["textLine3"].value.length;
                          }

                          const letterSize = Number(
                            aa["keys"][key]["fontSize"].name
                              .split(" ")[1]
                              .split("(")[1]
                          );
                          const diff = selectedKeyLength - letterSize;
                          const price =
                            this.props.totalQuantity *
                            -diff *
                            aa["keys"][key]["fontSize"].price;
                          if (diff > 0) {
                            this.addFontSizePriceToCart(price);
                          }

                          if (selectedKeyLengthtextLine2) {
                            let diff = selectedKeyLengthtextLine2 - letterSize;
                            const price =
                              this.props.totalQuantity *
                              -diff *
                              aa["keys"][key]["fontSize"].price;
                            if (diff > 0) {
                              this.addFontSizePriceToCart(price);
                            }
                          }
                          if (selectedKeyLengthtextLine3) {
                            let diff = selectedKeyLengthtextLine3 - letterSize;
                            const price =
                              this.props.totalQuantity *
                              -diff *
                              aa["keys"][key]["fontSize"].price;
                            if (diff > 0) {
                              this.addFontSizePriceToCart(price);
                            }
                          }
                        } else {
                          console.log("onDone - addPriceToCart");
                          this.props.addPriceToCart({
                            price:
                              this.props.totalQuantity *
                              -aa["keys"][key].imageSize.price,
                          });
                        }
                      }
                    }

                    //end
                    const index = this.cartEmbroidaryData.findIndex(
                      (i: any) => {
                        return i._id === embroidaryOption._id;
                      }
                    );
                    if (index !== -1) {
                      let aa = this.cartEmbroidaryData.find((i: any) => {
                        return i._id === embroidaryOption._id;
                      });
                      delete aa["keys"][key];
                    }
                  }
                }
              }
            }

            this.props.updateEmbroidaryRedux({
              ...this.state.embroidaryLocations,
              [this.props.selectedSide]: {
                ...emboidaryLoc,
              },
            });
            this.updatePreview(updatedArray);*/
          }}
        />
      </>
    );
  }
}

const MainContainer = styled.div`
  min-height: 500px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  display: flex;
  flex-direction: column;
`;

const SettingHeader = styled.div`
  margin-bottom: ${marginXL};
  p {
    margin-top: ${marginS};
  }
`;

const SecondaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const OptionButton = styled.div`
  background-color: #dde3e6;
  border: none;
  width: calc(50% - 64px);
  margin-right: ${marginM};
  margin-bottom: ${marginM};
  margin-top: ${marginM};
  padding: ${paddingS};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Divider = styled.div`
  height: 1px;
  background-color: #e3e3e3;
  margin: ${marginM} 0;
`;

const CtaContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ClearStepBtn = styled.button`
  margin-right: ${marginS};
  background: #dde3e6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  padding: ${paddingS} ${paddingM};
`;

const NextpBtn = styled.button`
  margin-right: ${marginS};
  background: ${primaryColor} 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  padding: ${paddingS} ${paddingM};
  color: #ffffff;
`;

const Wrapper = styled.div``;

const EmbroiederyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const WrapperForAgreement = styled.div`
  display: flex;
  margin-top: ${marginL};
`;

const mapStateToProps = (state: IAppState) => {
  const { embroidaryView } = state.embroidary;
  const { cartData } = state.cart;
  const { totalQuantity } = cartData;
  const {appliqueView} = state.applique;

  return {
    appliqueView,
    embroidaryView,
    totalQuantity,
    cartData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateEmbroidaryRedux: (embroidaryView: any) =>
      dispatch(updateEmbroidaryRedux(embroidaryView)),
    updateEmbroidaryToCart: (data: any) =>
      dispatch(updateEmbroidaryToCart(data)),
    addPriceToCart: (data: any) => dispatch(addPriceToCart(data)),
    updatePriceToCart: (data: any) => dispatch(updatePriceToCart(data)),
    removePriceToCart: (data: any) => dispatch(removePriceToCart(data)),
    updateEmbdryFrontPriceToCart: (data: any) =>
      dispatch(updateEmbdryFrontPriceToCart(data)),
    updateEmbdryBackPriceToCart: (data: any) =>
      dispatch(updateEmbdryBackPriceToCart(data)),
    updateEmbdryLeftPriceToCart: (data: any) =>
      dispatch(updateEmbdryLeftPriceToCart(data)),
    updateEmbdryRightPriceToCart: (data: any) =>
      dispatch(updateEmbdryRightPriceToCart(data)),
    updateEmbdryLeftCuffPriceToCart: (data: any) =>
      dispatch(updateEmbdryLeftCuffPriceToCart(data)),
    updateEmbdryRightCuffPriceToCart: (data: any) =>
      dispatch(updateEmbdryRightCuffPriceToCart(data)),
    updateEmbdryCollarPriceToCart: (data: any) =>
      dispatch(updateEmbdryCollarPriceToCart(data)),
    updateEmbdryCollarFlippedUpPriceToCart: (data: any) =>
      dispatch(updateEmbdryCollarFlippedUpPriceToCart(data)),
    updateEmbdryHoodPriceToCart: (data: any) =>
      dispatch(updateEmbdryHoodPriceToCart(data)),
    updateEmbdryHoodForwardPriceToCart: (data: any) =>
      dispatch(updateEmbdryHoodForwardPriceToCart(data)),
    updateEmbdryHoodBackwardPriceToCart: (data: any) =>
      dispatch(updateEmbdryHoodBackwardPriceToCart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmboideryLocations);
