import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Dropdown } from "@happeouikit/form-elements";

import Tab from "./Tab";

const CartInfo = ({ cartDataItem }) => {
  const [tab, setTab] = useState(1);
  const [appliqueView, setAppliqueView] = useState({
    label: "Front",
    value: "front",
  });

  const [embroidaryLocations, setEmbroidaryLocations] = useState([]);
  const [embroidaryLocationView, setEmbroidaryLocationView] = useState({});

  useEffect(() => {
    if (cartDataItem) {
      if (cartDataItem.customizedProducts.embroidaryId.length > 0) {
        const newA = cartDataItem.customizedProducts.embroidaryId.map((i) => {
          return {
            label: i.name,
            value: i.name,
          };
        });
        setEmbroidaryLocations(newA);
        setEmbroidaryLocationView(newA[0]);
      }
    }
  }, [cartDataItem]);

  const renderEm = cartDataItem.customizedProducts.embroidaryId.find((i) => {
    return i.name === embroidaryLocationView.value;
  });

  console.log(
    renderEm,
    "renderEm",
    cartDataItem,
    cartDataItem.customizedProducts.appliqueId[appliqueView.value].stitchingType
  );
  
  const formatPrice = (price) => {
    return parseFloat(price).toFixed(2);
  }

  return (
    <Container>
      <Flex>
        <Title></Title>
        <Tab onChange={setTab} tab={tab} />
      </Flex>
      {tab === 1 && (
        <Flex>
          <Table>
            <tbody border="collapse">
              <tr>
                <td
                  style={{
                    fontSize: "1em",
                    textTransform: "uppercase",
                  }}
                >
                  Color:
                </td>

                <td style={{ color: "#888383" }}>
                  {cartDataItem.customizedProducts?.colors &&
                    cartDataItem.customizedProducts?.colors?.colorName
                      .charAt(0)
                      .toUpperCase() +
                      cartDataItem.customizedProducts?.colors?.colorName.slice(
                        1
                      )}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: "1em",
                    textTransform: "uppercase",
                  }}
                >
                  Base Price:
                </td>
                <td style={{ color: "#888383" }}>
                  {formatPrice(cartDataItem.totalQuantity)} item(s)
                </td>
                <td style={{ color: "#888383" }}>
                  {cartDataItem.totalQuantity} x {cartDataItem.currency}
                  {formatPrice(cartDataItem.productId.price)}
                </td>
                <td style={{ color: "#888383" }}>
                  {cartDataItem.currency}
                  {formatPrice(cartDataItem.totalQuantity * cartDataItem.productId.price)}
                </td>
              </tr>
              <tr>
                <td>Qty & Size:</td>
                <td style={{ color: "#888383" }}>
                  {cartDataItem.customizedProducts?.colors &&
                    cartDataItem.customizedProducts?.colors[
                      "size&quantity"
                    ].map((i) => (
                      <div>
                        {i.quantity} - {i.sizeCode}
                      </div>
                    ))}
                </td>
                <td style={{ color: "#888383" }}>
                  {cartDataItem.customizedProducts?.colors &&
                    cartDataItem.customizedProducts.colors["size&quantity"].map(
                      (i) => (
                        <div>
                          {i.quantity} x {cartDataItem.currency} {formatPrice(i.sizePrice)}
                        </div>
                      )
                    )}
                </td>
                <td style={{ color: "#888383" }}>
                  {cartDataItem.customizedProducts?.colors &&
                    cartDataItem.customizedProducts.colors["size&quantity"].map(
                      (i) => (
                        <div>
                          {cartDataItem.currency} {formatPrice(i.quantity * i.sizePrice)}
                        </div>
                      )
                    )}
                </td>
              </tr>
            </tbody>
          </Table>
        </Flex>
      )}
      {tab === 2 && (
        <>
          <Flex>
            <h4>Applique</h4>
            <Dropdown
              options={[
                { label: "Front", value: "front" },
                { label: "Back", value: "back" },
              ]}
              onChange={(val) => {
                setAppliqueView(val);
              }}
              value={appliqueView}
            />
          </Flex>
          <Flex>
            <Table>
              <tbody>
                <tr>
                  {cartDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].stitchingType && (
                    <>
                      <td
                        style={{
                          fontSize: "1em",
                          textTransform: "uppercase",
                        }}
                      >
                        Stitching:
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].stitchingType.stitchingType
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {cartDataItem.totalQuantity} x{" "}
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].stitchingType.currency
                        }
                        {
                          formatPrice(cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].stitchingType.price)
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].stitchingType.currency
                        }
                        {formatPrice(cartDataItem.totalQuantity *
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].stitchingType.price)}
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                  <td
                    style={{
                      fontSize: "1em",
                      textTransform: "uppercase",
                    }}
                  >
                    Organization:
                  </td>
                  <td style={{ color: "#888383" }}>
                    {
                      cartDataItem.customizedProducts.appliqueId[
                        appliqueView.value
                      ].organization
                    }
                  </td>
                </tr>
                <tr>
                  {cartDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].letterSize && (
                    <>
                      <td
                        style={{
                          fontSize: "1em",
                          textTransform: "uppercase",
                        }}
                      >
                        Letter Size:
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].letterSize.size
                        }
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                  <td
                    style={{
                      fontSize: "1em",
                      textTransform: "uppercase",
                    }}
                  >
                    Placement:
                  </td>
                  <td style={{ color: "#888383" }}>
                    {
                      cartDataItem.customizedProducts.appliqueId[
                        appliqueView.value
                      ].letterPlacement
                    }
                  </td>
                </tr>
                <tr>
                  {cartDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].foreground.foregroundMaterial && (
                    <>
                      <td
                        style={{
                          fontSize: "1em",
                          textTransform: "uppercase",
                        }}
                      >
                        Foreground:
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].foreground.foregroundMaterial.name
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {cartDataItem.totalQuantity} x{" "}
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].foreground.foregroundMaterial.currency
                        }
                        {
                          formatPrice(cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].foreground.foregroundMaterial.price)
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].foreground.foregroundMaterial.currency
                        }
                        {formatPrice(cartDataItem.totalQuantity *
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].foreground.foregroundMaterial.price)}
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                  {cartDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].foreground.foregroundColor && (
                    <>
                      <td
                        style={{
                          fontSize: "1em",
                          textTransform: "uppercase",
                        }}
                      >
                        Foreground Color:
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].foreground.foregroundColor.name
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {cartDataItem.totalQuantity} x{" "}
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].foreground.foregroundColor.currency
                        }
                        {
                          formatPrice(cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].foreground.foregroundColor.price)
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].foreground.foregroundColor.currency
                        }
                        {formatPrice(cartDataItem.totalQuantity *
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].foreground.foregroundColor.price)}
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                  {cartDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].foreground.threadColor && (
                    <>
                      <td
                        style={{
                          fontSize: "1em",
                          textTransform: "uppercase",
                        }}
                      >
                        FG Thread:
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].foreground.threadColor.name
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {cartDataItem.totalQuantity} x{" "}
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].foreground.threadColor.currency
                        }
                        {
                          formatPrice(cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].foreground.threadColor.price)
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].foreground.threadColor.currency
                        }
                        {formatPrice(cartDataItem.totalQuantity *
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].foreground.threadColor.price)}
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                  {cartDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].background.backgroundMaterial && (
                    <>
                      <td
                        style={{
                          fontSize: "1em",
                          textTransform: "uppercase",
                        }}
                      >
                        Background:
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].background.backgroundMaterial.name
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {cartDataItem.totalQuantity} x{" "}
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].background.backgroundMaterial.currency
                        }
                        {
                          formatPrice(cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].background.backgroundMaterial.price)
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].background.backgroundMaterial.currency
                        }
                        {formatPrice(cartDataItem.totalQuantity *
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].background.backgroundMaterial.price)}
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                  {cartDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].background.backgroundColor && typeof cartDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].background.backgroundColor.name !== "undefined" && (
                    <>
                      <td
                        style={{
                          fontSize: "1em",
                          textTransform: "uppercase",
                        }}
                      >
                        Background Color : 
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].background.backgroundColor.name
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {cartDataItem.totalQuantity} x{" "}
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].background.backgroundColor.currency
                        }
                        {
                          formatPrice(cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].background.backgroundColor.price)
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].background.backgroundColor.currency
                        }
                        {formatPrice(cartDataItem.totalQuantity *
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].background.backgroundColor.price)}
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                  {cartDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].background.threadColor && (
                    <>
                      <td
                        style={{
                          fontSize: "1em",
                          textTransform: "uppercase",
                        }}
                      >
                        BG Thread:
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].background.threadColor.name
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {cartDataItem.totalQuantity} x{" "}
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].background.threadColor.currency
                        }
                        {
                          formatPrice(cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].background.threadColor.price)
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].background.threadColor.currency
                        }
                        {formatPrice(cartDataItem.totalQuantity *
                          cartDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ].background.threadColor.price)}
                      </td>
                    </>
                  )}
                </tr>
                {cartDataItem.customizedProducts.appliqueId[appliqueView.value]
                  .heatSealedLetter && (
                  <tr>
                    <td
                      style={{
                        fontSize: "1em",
                        textTransform: "uppercase",
                      }}
                    >
                      Heat Sealed:
                    </td>
                    <td style={{ color: "#888383" }}>
                      {
                        `${cartDataItem.customizedProducts.appliqueId[appliqueView.value].heatSealedLetter?"Enabled":"Disabled"}`
                      }
                    </td>
                    <td style={{ color: "#888383" }}>
                      {cartDataItem.totalQuantity} x{" "}
                      {
                        cartDataItem.currency
                      }
                      {
                        `${cartDataItem.customizedProducts.appliqueId[appliqueView.value].heatSealedLetter?"2":"0"}`
                      }
                    </td>
                    <td style={{ color: "#888383" }}>
                      {
                        cartDataItem.currency
                      }
                      {cartDataItem.totalQuantity * (cartDataItem.customizedProducts.appliqueId[appliqueView.value].heatSealedLetter?2:0)}
                    </td>
                  </tr>
                )}

                <tr>
                  <td
                    style={{
                      fontSize: "1em",
                      textTransform: "uppercase",
                    }}
                  >
                    Special Instruction's:
                  </td>
                  <td style={{ color: "#888383" }}>
                    {
                      cartDataItem.customizedProducts.appliqueId[
                        appliqueView.value
                      ].specialInstruction
                    }
                  </td>
                </tr>
              </tbody>
            </Table>
          </Flex>
        </>
      )}
      {tab === 3 && (
        <>
          <Flex>
            <h4>
              <strong>Embroidary Locations</strong>
            </h4>
            <Dropdown
              options={embroidaryLocations}
              onChange={(val) => {
                console.log(val, "valval");
                setEmbroidaryLocationView(val);
              }}
              value={embroidaryLocationView}
            />
          </Flex>
          <Flex>
            <Table>
              <tbody>
                {renderEm &&
                  (renderEm.name !==
                  "Crest and Images ( Price varies with size and design )" ? (
                    <>
                      <tr>
                        <td></td>
                        {Object.keys(renderEm.keys).map((k) => (
                          <td
                            style={{
                              color: "#888383",
                              fontWeight: "bold",
                              fontStyle: "italic",
                            }}
                          >
                            {k}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              fontSize: "1em",
                              textTransform: "uppercase",
                            }}
                          >
                            Base Price:
                          </b>
                        </td>
                        {Object.keys(renderEm.keys).map((k) => {
                          const sel = renderEm.basePrice.find(
                            (l) => k === l.name
                          );
                          return (
                            <>
                              <td style={{ color: "#888383" }}>
                                {cartDataItem.totalQuantity}
                                {"x"}
                                {sel.currency}
                                {formatPrice(sel.price)}
                              </td>
                            </>
                          );
                        })}
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontSize: "1em",
                            textTransform: "uppercase",
                          }}
                        >
                          <b>Font Style:</b>
                        </td>
                        {Object.keys(renderEm.keys).map((k) => (
                          <td style={{ color: "#888383" }}>
                            {renderEm.keys[k].fontStyle.name}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              fontSize: "1em",
                              textTransform: "uppercase",
                            }}
                          >
                            Font Size:
                          </b>
                        </td>
                        {Object.keys(renderEm.keys).map((k) => (
                          <td style={{ color: "#888383" }}>
                            {renderEm.keys[k].fontSize.name}{" "}
                            {renderEm.keys[k].fontSize.currency}
                            {formatPrice(renderEm.keys[k].fontSize.price)}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              fontSize: "1em",
                              textTransform: "uppercase",
                            }}
                          >
                            Thread Color:
                          </b>
                        </td>
                        {Object.keys(renderEm.keys).map((k) => (
                          <td style={{ color: "#888383" }}>
                            {renderEm.keys[k].threadColor.name}{" "}
                            {renderEm.keys[k].threadColor.currency}
                            {formatPrice(renderEm.keys[k].threadColor.price)}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              fontSize: "1em",
                              textTransform: "uppercase",
                            }}
                          >
                            Text Line 1:
                          </b>
                        </td>
                        {Object.keys(renderEm.keys).map((k) => (
                          <td style={{ color: "#888383" }}>
                            {renderEm.keys[k].textLine1.value}
                          </td>
                        ))}
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <td></td>
                        {Object.keys(renderEm.keys).map((k) => (
                          <td>{k}</td>
                        ))}
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              fontSize: "1em",
                              textTransform: "uppercase",
                            }}
                          >
                            Base Price:
                          </b>
                        </td>
                        {Object.keys(renderEm.keys).map((k) => {
                          const sel = renderEm.basePrice.find(
                            (l) => k === l.name
                          );
                          return (
                            <>
                              <td style={{ color: "#888383" }}>
                                {cartDataItem.totalQuantity}
                                {"x"}
                                {sel.currency}
                                {formatPrice(sel.price)}
                              </td>
                            </>
                          );
                        })}
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              fontSize: "1em",
                              textTransform: "uppercase",
                            }}
                          >
                            Embroidary Location:
                          </b>
                        </td>
                        {Object.keys(renderEm.keys).map((k) => (
                          <td style={{ color: "#888383" }}>
                            {renderEm.keys[k].embroidaryLocation.name}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              fontSize: "1em",
                              textTransform: "uppercase",
                            }}
                          >
                            Image Size:
                          </b>
                        </td>
                        {Object.keys(renderEm.keys).map((k) => (
                          <td style={{ color: "#888383" }}>
                            {renderEm.keys[k].imageSize.name}{" "}
                          </td>
                        ))}
                      </tr>
                      {/* <tr>
                        <td>
                          <b
                            style={{
                              fontSize: "1em",
                              textTransform: "uppercase",
                            }}
                          >
                            Text
                          </b>
                        </td>
                        {Object.keys(renderEm.keys).map((k) => (
                          <td style={{ color: "#888383" }}>
                            {renderEm.keys[k].text.value}{" "}
                          </td>
                        ))}
                      </tr> */}
                    </>
                  ))}
              </tbody>
            </Table>
          </Flex>
        </>
      )}
    </Container>
  );
};

export default CartInfo;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled.div`
  margin: 15px 0 15px 0;
`;

const Title = styled.div`
  max-width: 50%;
  text-align: left;
  font: normal normal normal 16px/20px Source Sans Pro;
  letter-spacing: 0.51px;
  color: #424447;
  opacity: 1;
`;

const Table = styled.table`
  all: unset;
  margin-top: 10px;
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    padding: 8px;
    width: 20%;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04aa6d;
    color: white;
  }
`;
