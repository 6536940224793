export const FloaterIcon5 = () => (
    <svg id="Cart" data-tip data-for="Cart_tooltip" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
        <rect id="Rectangle_168" data-name="Rectangle 168" width="36" height="36" fill="none" />
        <g id="Group_87" data-name="Group 87" transform="translate(-4 -4)">
            <path id="Path_12" data-name="Path 12" d="M7.3,7.6A2.5,2.5,0,0,1,9.7,9.674l2.207,16.877a1.272,1.272,0,0,0,1.3,1.1H33.978a1.32,1.32,0,0,0,1.3-1.1L37.223,12.27a1.26,1.26,0,0,0-.325-1.039,1.592,1.592,0,0,0-1.039-.389H12.426l-.195-1.493A4.977,4.977,0,0,0,7.3,5a1.3,1.3,0,1,0,0,2.6Zm27.069,5.842L32.744,25.123H14.309L12.816,13.439Z" transform="translate(0 0)" fill="rgba(66,68,71,0.62)" />
            <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.298" cy="1.298" r="1.298" transform="translate(15.737 36.807)" fill="rgba(66,68,71,0.62)" />
            <circle id="Ellipse_4" data-name="Ellipse 4" cx="1.298" cy="1.298" r="1.298" transform="translate(28.72 36.807)" fill="rgba(66,68,71,0.62)" />
            <path id="Path_13" data-name="Path 13" d="M18.3,34.909a1.3,1.3,0,0,0-1.3,1.3A3.79,3.79,0,0,0,20.895,40.1h14.93a1.3,1.3,0,1,0,0-2.6H20.895a1.184,1.184,0,0,1-1.3-1.3A1.3,1.3,0,0,0,18.3,34.909Zm5.842-9.088h3.765L27,26.99a1.26,1.26,0,0,0,.195,1.818,1.247,1.247,0,0,0,.779.26,1.355,1.355,0,0,0,1.039-.519l2.6-3.246a1.273,1.273,0,0,0,0-1.623l-2.6-3.246A1.293,1.293,0,0,0,27,22.056l.909,1.168H24.14a1.3,1.3,0,1,0,0,2.6Z" transform="translate(-3.86 -5.242)" fill="rgba(66,68,71,0.62)" />
        </g>
    </svg>
);
