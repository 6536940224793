export const primaryColor = "#142F8F";
export const secondaryColor = "#424447";
export const tertaryColor = "#808080";
export const themeWhite = "#ffffff";
// standard Colors
export const PrimaryBgColor = "#142f8e";
export const SecondaryBgColor = "#f2f0e9";

export const PrimaryFontColor = "#ffffff";
export const SecondaryFontColor = "#142f8e";

export const DangerColor = "#142f8e";
export const WarningColor = "blue";

// Standard Paddings
export const paddingS = "8px";
export const paddingM = "16px";
export const paddingL = "24px";
export const paddingXL = "32px";
export const paddingXXL = "40px";
export const paddingMixer = (times: number) => `${times * 8}px`;

// Standard Margins
export const marginS = "8px";
export const marginM = "16px";
export const marginL = "24px";
export const marginXL = "32px";
export const marginXXL = "40px";
export const MargingMixer = (times: number) => `${times * 8}px`;

//standard font sizes
export const fontS = "8px";
export const fontM = "16px";
export const fontL = "24px";
export const fontXL = "32px";
export const fontXXL = "40px";
export const fontXXXL = "48px";
export const fontXXXXL = "56px";
export const fontXXXXXL = "64px";
export const fontEven = (size: number) => `${size * 2}px`;
export const fontOdd = (size: number) => `${size * 2 + 1}px`;

// Break points
// ===========================
export const screenSize = {
  xs: "0px",
  xxsUp: "24rem",
  xsUp: "48rem",
  sm: "48.063rem",
  smUp: "62rem",
  md: "62.063rem",
  mdUp: "82rem",
  lg: "82.063rem",
  lgUp: "100rem",
  xl: "100rem",
  xlUp: "115.063rem",
  xxl: "115.063rem",
};

// screenSize size
// ===========================
export const screen = {
  minSm: `only screenSize and (min-width: ${screenSize.sm})`,
  minMd: `only screenSize and (min-width: ${screenSize.md})`,
  minLg: `only screenSize and (min-width: ${screenSize.lg})`,
  minXl: `only screenSize and (min-width: ${screenSize.xl})`,
  minXXl: `only screenSize and (min-width: ${screenSize.xxl})`,
  maxLg: `only screen and (max-width: ${screenSize.lgUp})`,
  onlyXxs: `(min-width: 0em) and (max-width: ${screenSize.xxsUp})`,
  onlyXs: `(min-width: 0em) and (max-width: ${screenSize.xsUp})`,
  onlySm: `(min-width: ${screenSize.sm}) and (max-width: ${screenSize.smUp})`,
  onlyMd: `(min-width: ${screenSize.md}) and (max-width: ${screenSize.mdUp})`,
  onlyLg: `(min-width: ${screenSize.lg}) and (max-width: ${screenSize.lgUp})`,
  onlyXl: `(min-width: ${screenSize.xl}) and (max-width: ${screenSize.xlUp})`,
  onlyXxl: `(min-width: ${screenSize.xxl})`,
};

/**
 * Compare width to screen size by converting screenSize's rems to px
 * @param width
 * @param screenSize
 * @returns {boolean}
 */
const checkScreen = (width: number | string, screenSize: string) => {
  const rem = parseInt(screenSize, 10);
  return width > 0 && width < convertRemToPixels(rem);
};

export const isMobile = (width: number) => checkScreen(width, screenSize.xsUp);

// check for mobile + tablet screen sizes
export const isSm = (width: number) => checkScreen(width, screenSize.smUp);

const convertRemToPixels = (rem: number) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export const designWindowHeightValue = 5;

export const designWindowHeightUnit = "in";

export const designWindowHeight = `${designWindowHeightValue}${designWindowHeightUnit}`;

export const defaultFontFamily = "Arial, Helvetica, san-serif"; //Open Sans
