import React from "react";
import {
  CategoryResponseDatum,
  ICategory,
  IProduct,
  ISubcategory,
} from "../../models";
import { IOneProductV2 } from "../../models/ApiResponses/IGetProductResponseV2";
import { PAGE_TYPE } from "./Catalog.page";
import { Items, Item, Img, Desc } from "./styled.component";

interface CategoriesPageProps {
  data: Array<ISubcategory | CategoryResponseDatum | IOneProductV2>;
  handleClick(id: string): void;
  pageType: PAGE_TYPE;
  filterText: string;
  isProduct: boolean;
}
interface CategoriesPageState {
  results: any;
}

export class CategoriesPage extends React.Component<
  CategoriesPageProps,
  CategoriesPageState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      results: [],
    };
  }

  getImageComponent(item: ISubcategory | CategoryResponseDatum | IProduct) {
    if (this.props.pageType === PAGE_TYPE.SUBCAT) {
      const data = item as ISubcategory;
      return <Img src={data.image} />;
    } else if (this.props.pageType === PAGE_TYPE.CAT) {
      const data = item as any as ISubcategory;
      return <Img src={data.image} />;
    } else {
      const data = item as any as IOneProductV2;
      return <Img src={data?.colors[0]?.images?.front} />;
    }
  }

  render() {
    let { data, handleClick } = this.props;
    if (this.props.filterText.length > 1) {
      data = data.filter((e) => {
        return e.name.toLowerCase().match(this.props.filterText.toLowerCase());
      });
    }
    return (
      <>
        <Items>
          {data.map((currentItem: any) => {
            return (
              <Item
                key={currentItem._id}
                onClick={() => handleClick(currentItem?._id)}
              >
                {this.getImageComponent(currentItem as any)}

                <Desc>{currentItem.name}</Desc>
              </Item>
            );
          })}
        </Items>
      </>
    );
  }
}
